import { useLazyQuery } from "@apollo/client";
import { GetCampaignByIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getCampaignById } from "../queries/getCampaignById";

export type UseGetCampaignById = ReturnType<typeof useGetCampaignById>;

export const useGetCampaignById = () => {
  const [fetchCampaignById, { data, loading, error }] =
    useLazyQuery<GetCampaignByIdQuery>(getCampaignById);

  return {
    campaign: {
      id: data?.campaign_by_pk?.id,
      name: data?.campaign_by_pk?.name,
      statusId: data?.campaign_by_pk?.campaign_status_id,
      workspaceId: data?.campaign_by_pk?.workspace_id,
    },
    campaignError: error,
    campaignLoading: loading,
    fetchCampaignById,
  };
};
