import { gql } from "@apollo/client";

export const getUsers = gql`
  query getUsers {
    user {
      id
      name
      email
    }
  }
`;
