import { gql } from "@apollo/client";

export const getWorkspacesPaginated = gql`
  query getWorkspacesPaginated($offset: Int, $limit: Int, $orderBy: [workspace_order_by!]) {
    workspace_aggregate {
      aggregate {
        count
      }
    }
    workspace(offset: $offset, limit: $limit, order_by: $orderBy) {
      id
      name
      workspace_status_id
    }
  }
`;
