import { useLazyQuery } from "@apollo/client";
import { GetAddressesByPostcodeUnitIdsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getAddressesByPostcodeUnitIds } from "../queries/getAddressesByPostcodeUnitIds";

export type UseLazyAddressesByPostcodeUnitIdsReturnType = ReturnType<
  typeof useLazyAddressesByPostcodeUnitIds
>;

export interface TransformedAddressesForPostcodeUnitIds {
  postcodeUnitId: number;
  postcodeUnitCode: string;
  addresses: TransformedAddressForPostcodeUnitIdsInfo[];
}

interface TransformedAddressForPostcodeUnitIdsInfo {
  id: number;
  fullAddress: string;
  uprn: string;
}

export interface GroupedAddresses {
  [key: number]: {
    postcodeUnitId: number;
    postcodeUnitCode: string;
    addresses: TransformedAddressForPostcodeUnitIdsInfo[];
  };
}

export const transformAddressesByPostcodeUnitIdsResult = ({
  data,
}: {
  data: GetAddressesByPostcodeUnitIdsQuery;
}): TransformedAddressesForPostcodeUnitIds[] => {
  // Reduce the array of addresses into an object grouped by postcodeUnitId
  const groupedByPostcodeUnitId = data.address.reduce<GroupedAddresses>((acc, address) => {
    if (!acc[address.postcode_unit.id]) {
      acc[address.postcode_unit.id] = {
        postcodeUnitId: address.postcode_unit.id,
        postcodeUnitCode: address.postcode_unit.code,
        addresses: [],
      };
    }
    acc[address.postcode_unit.id].addresses.push({
      id: address.id,
      fullAddress: address.full_address,
      uprn: address.uprn,
    });
    return acc;
  }, {});

  // Convert the object into an array of the grouped data
  return Object.values(groupedByPostcodeUnitId);
};

export const useLazyAddressesByPostcodeUnitIds = () => {
  const [getAddressesByPostcodeUnitIdsExec, { data, loading, error }] = useLazyQuery(
    getAddressesByPostcodeUnitIds
  );

  const aggregatedAddresses = data ? transformAddressesByPostcodeUnitIdsResult({ data }) : [];

  const getLazyAddressesByPostcodeUnitIds = async (postcodeUnitIds: number[]) => {
    await getAddressesByPostcodeUnitIdsExec({
      variables: { postcodeUnitIds },
    });
  };

  return {
    getLazyAddressesByPostcodeUnitIds,
    addresses: data && Array.isArray(data.address) ? data.address : [],
    aggregatedAddresses,
    addressesError: error,
    addressesLoading: loading,
  };
};
