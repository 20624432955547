import { gql } from "@apollo/client";

export const getWorkspaces = gql`
  query getWorkspaces($orderBy: [workspace_order_by!]) {
    workspace(order_by: $orderBy) {
      id
      name
      workspace_status_id
    }
  }
`;
