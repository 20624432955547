import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface AddOrRemoveBlacklistedAgentsBody {
  workspaceId: number;
  parentAgentIds: number[];
  branchAgentIds: number[];
  action: "addExclusions" | "removeExclusions";
}

export const addOrRemoveBlacklistedAgentsAPI = async ({
  workspaceId,
  branchAgentIds,
  parentAgentIds,
  action,
}: AddOrRemoveBlacklistedAgentsBody) => {
  const payload = {
    branchAgentIds,
    parentAgentIds,
    action,
  };

  const response = await meetmoreAPI.post(
    `/workspaces/${workspaceId}/add-or-remove-blacklisted-agents`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const useAddOrRemoveBlacklistedAgents = () => {
  let loadingToastId: string;

  return useMutation(addOrRemoveBlacklistedAgentsAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Editing workspace blacklisted agents...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Workspace Blacklisted Agents Edited!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
