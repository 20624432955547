import { ReactElement } from "react";
import SignIn from "../../../modules/authentication/signIn/page/SignIn";
import ForgotPassword from "../../../modules/authentication/forgotPassword/page/ForgotPass";
import AccessDenied from "../../../modules/authentication/accessDenied/page/AccessDenied";
import Workspaces from "../../../modules/workspaces/workspaces/page/Workspaces";
import SingleWorkspaceGeneral from "../../../modules/workspaces/singleWorkspaceGeneral/page/SingleWorkspaceGeneral";
import SingleWorkspaceChannels from "../../../modules/workspaces/singleWorkspaceChannels/page/SingleWorkspaceChannels";
import SingleWorkspaceTeamMembers from "../../../modules/workspaces/singleWorkspaceTeamMembers/page/SingleWorkspaceTeamMembers";
import Overview from "../../../modules/analytics/overview/page/Overview";
import Conversations from "../../../modules/conversations/conversations/page/Conversations";
import Campaigns from "../../../modules/campaigns/campaigns/page/Campaigns";
import CommissionCampaign from "../../../modules/campaigns/commissionCampaign/page/CommissionCampaign";
import GetOwners from "../../../modules/getOwners/workspaces/page/GetOwners";

export interface Route {
  path: string;
  exact: boolean;
  main: (any: any) => ReactElement;
  redirectPath?: string;
}

// Routes that can be accessed anytime
export const publicRoutes: Route[] = [
  {
    path: "/access-denied",
    main: AccessDenied,
    exact: true,
  },
];

// routes that can be access when user is inauthenticated
export const restrictedRoutes: Route[] = [
  {
    path: "/",
    main: SignIn,
    exact: true,
  },

  {
    path: "/forgot-password",
    main: ForgotPassword,
    exact: true,
  },
];

// routes that can be accessed if user is authenticated

// export const navigation = [
//   { name: "Overview", path: "/analytics", icon: House },
//   {
//     name: "Conversations",
//     path: "/conversations",
//     icon: Inbox,
//   },
//   {
//     name: "Workspaces",
//     path: "/workspaces",
//     icon: Boxes,
//   },
//   {
//     name: "Campaigns",
//     path: "/campaigns",
//     icon: SwatchBook,
//   },
//   {
//     name: "Sequences",
//     path: "/sequences",
//     icon: Send,
//   },
// ] as const;

export const adminLayoutRoutes: Route[] = [
  {
    path: "/analytics",
    main: Overview,
    exact: true,
  },
  {
    path: "/conversations",
    main: Conversations,
    exact: true,
  },
  // Workspaces
  {
    path: "/workspaces",
    main: Workspaces,
    exact: true,
  },

  // Campaigns
  {
    path: "/campaigns",
    main: Campaigns,
    exact: true,
  },

  {
    path: "/sequences",
    main: () => <div>Sequences</div>,
    exact: true,
  },
  {
    path: "/get-owners",
    main: GetOwners,
    exact: true,
  },
];

export const adminNonLayoutRoutes: Route[] = [
  {
    path: "/campaigns/commission",
    main: CommissionCampaign,
    exact: true,
  },
];

export const singleWorkspaceRoutes: Route[] = [
  {
    path: "/workspaces/:workspaceId/general",
    main: SingleWorkspaceGeneral,
    exact: true,
  },
  {
    path: "/workspaces/:workspaceId/team-members",
    main: SingleWorkspaceTeamMembers,
    exact: true,
  },
  {
    path: "/workspaces/:workspaceId/channels",
    main: SingleWorkspaceChannels,
    exact: true,
  },
];
