import { gql } from "@apollo/client";

export const getPostcodeUnitsByCode = gql`
  query getPostcodeUnitsByCode($code: String!, $limit: Int!) {
    postcode_unit(where: { code: { _ilike: $code } }, limit: $limit) {
      id
      code
    }
  }
`;
