import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface CommissionOnMarketAPIBody {
  campaignTypeId: number;
  workspaceId: number;
  outreachAgentIds: number[];
  postcodeDistrictIds: number[];
  filters: {
    price: {
      min: number;
      max: number;
    };
    daysOnMarket: {
      min: number;
      max: number;
    };
    maximumAddresses: number;
  };
  messageSetId: number;
  enabledOwnershipSourceIds: number[];
  automationLevelId: number;
  campaignName: string;
}

export const commissionOnMarketAPI = async (data: CommissionOnMarketAPIBody) => {
  const payload = {
    ...data,
  };

  const response = await meetmoreAPI.post(`/campaigns/commission/on-market`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useCommissionOnMarketAPI = () => {
  let loadingToastId: string;

  return useMutation(commissionOnMarketAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Commission On Market campaign...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("On Market Campaign Commissioned!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
