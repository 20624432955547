import { gql } from "@apollo/client";

export const getRepliedCampaignLeadProfiles = gql`
  query getRepliedCampaignLeadProfiles(
    $orderBy: [campaign_lead_profile_order_by!]
    $saleActionStatusIds: [Int!]
    $workspacesIds: [Int!]
    $limit: Int
    $offset: Int
    $nameFilter: String
    $lastReplyDaysAgo: timestamptz
    $conversationValueMin: Int
    $conversationValueMax: Int
  ) {
    campaign_lead_profile_aggregate(
      where: {
        conversation_thread_id: { _is_null: false }
        last_sale_action_status_id: { _in: $saleActionStatusIds }
        digital_profile: { name: { _ilike: $nameFilter } }
        campaign_lead: { campaign: { workspace_id: { _in: $workspacesIds } } }
        _and: [
          { last_reply_external_created_at: { _gte: $lastReplyDaysAgo } }
          { last_reply_external_created_at: { _is_null: false } }
        ]
        conversation_value: { _gte: $conversationValueMin, _lte:$conversationValueMax } }
    ) {
      aggregate {
        count
      }
    }

    campaign_lead_profile(
      where: {
        conversation_thread_id: { _is_null: false }
        last_sale_action_status_id: { _in: $saleActionStatusIds }
        digital_profile: { name: { _ilike: $nameFilter } }
        campaign_lead: { campaign: { workspace_id: { _in: $workspacesIds } } }
        _and: [
          { last_reply_external_created_at: { _gte: $lastReplyDaysAgo } }
          { last_reply_external_created_at: { _is_null: false } }
        ]
        conversation_value: { _gte: $conversationValueMin, _lte:$conversationValueMax } 
      }
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      campaign_lead {
        campaign {
          workspace {
            name
          }
          campaign_type {
            id
            name
          }
        }
      }
      ## conversation value related
      conversation_value
      campaign_lead_profile_property_values(order_by: { id: asc }) {
        id
        property_value
        campaign_lead_profile_property_value_source_type {
          id
          name
        }
      }
      last_sale_action_status: sale_action_status {
        id
        name
      } 
      campaign_lead_profile_sale_action_statuses(order_by: { id: asc }) {
        id
        sale_action_status {
          id
          name
        }
        campaign_lead_profile_sale_action_status_source_type {
          id
          name
        }
      }
      ## conversation thread related
      conversation_thread {
        is_managed_by_user: user {
          email
          id
          name
        }
        id
        conversation_messages {
          is_outbound
          admin_seen_at
        }
        last_inbound_message: conversation_messages(
          where: { is_outbound: { _eq: false } }
          order_by: { external_created_at: desc }
          limit: 1
        ) {
          id
          external_created_at
        }
      }
      # profile related
      digital_profile {
        id
        name
        digital_profile_type {
          id
          name
        }
      }
      ## outstanding tags
      is_incorrect_match
      is_personal_connection
    }
  }
`;
