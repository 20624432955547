import { getCampaignTypes } from "./getCampaignTypes";
import { GetCampaignTypesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseCampaignTypes = ReturnType<typeof useCampaignTypes>;

export const useCampaignTypes = () => {
  const { data, loading, error } = useQuery<GetCampaignTypesQuery>(getCampaignTypes);
  return {
    campaignTypes: data && Array.isArray(data.campaign_type) ? data.campaign_type : [],
    campaignTypesError: error,
    campaignTypesLoading: loading,
  };
};
