import * as yup from "yup";

export type UpdateWorkspaceValues = {
  name: string;
  workspaceStatusId: string;
};

export const updateWorkspaceValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  workspaceStatusId: yup.string().required("Status is required"),
});

export type EditWorkspaceBlacklistedAgentsValues = {
  branchAgents: { label: string; value: number }[];
};

export const editWorkspaceBlacklistedAgents = yup.object().shape({
  branchAgents: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .typeError("Please select at least one Branch Agent")
    )
    .min(1, "Please select at least one Branch Agent")
    .required("Please select at least one Branch Agent")
    .defined("Please select at least one Branch Agent"),
});
