import { useQuery } from "@apollo/client";
import { getWorkspaceById } from "../queries/getWorkspaceById";
import {
  GetWorkspaceByIdQuery,
  GetWorkspaceByIdQueryVariables,
} from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseWorkspaceById = ReturnType<typeof useWorkspaceById>;

export const useWorkspaceById = ({ id }: { id: number }) => {
  const { data, loading, error, refetch } = useQuery<
    GetWorkspaceByIdQuery,
    GetWorkspaceByIdQueryVariables
  >(getWorkspaceById, {
    variables: {
      id,
    },
    skip: !id,
  });

  return {
    workspace: data && data?.workspace_by_pk ? data.workspace_by_pk : null,
    workspaceError: error,
    workspaceLoading: loading,
    refetchWorkspace: async () => {
      await refetch();
    },
  };
};
