import { getWorkspaceStatuses } from "../queries/getWorkspaceStatuses";
import { GetWorkspaceStatusesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseWorkspaceStatuses = ReturnType<typeof useWorkspaceStatuses>;

export const useWorkspaceStatuses = () => {
  const { data, loading, error } = useQuery<GetWorkspaceStatusesQuery>(getWorkspaceStatuses);
  return {
    workspaceStatuses: data && Array.isArray(data.workspace_status) ? data.workspace_status : [],
    workspaceStatusesError: error,
    workspaceStatusesLoading: loading,
  };
};
