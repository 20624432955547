import { useEffect, useState } from "react";
import { useCampaignTypes } from "../../../../shared/domains/static/graphql/hooks/useCampaignTypes";
import { useForm } from "react-hook-form";
import { CommisionCampaignValues, commissionCampaignSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import IntentSignalSelection from "./components/IntentSignalSelection";
import { useWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { cx } from "../../../../shared/utils";
import useScroll from "../../../../shared/hooks/UseScroll";
import OnMarketFilters from "./components/OnMarketFilters";
import { usePostcodeDistricts } from "../../../../shared/domains/postcodes/graphql/hooks/usePostcodeDistricts";
import { defaultCommisionCampaignValues } from "./data";
import { useMessageSets } from "../../../../shared/domains/messageSets/graphql/hooks/useMessageSets";
import Sequences from "./components/Sequences";
import Owners from "./components/Owners";
import { useOwnershipSources } from "../../../../shared/domains/static/graphql/hooks/useOwnershipSources";
import Automations from "./components/Automations";
import CampaignName from "./components/CampaignName";
import { useCommissionOnMarketAPI } from "../../../../shared/domains/campaigns/rest/commissionOnMarketAPI";
import { useNavigate } from "react-router-dom";

const CommissionCampaign = () => {
  const { campaignTypes } = useCampaignTypes();
  const { activeWorkspaces } = useWorkspaces({
    orderBy: [{ name: "asc" }],
  });
  const { messageSets } = useMessageSets();
  const { ownershipSources } = useOwnershipSources();
  const { postcodeDistricts } = usePostcodeDistricts();
  const {
    mutateAsync: commissionOnMarketMutation,
    isSuccess: commissionOnMarketMutationSuccess,
    isLoading: commissionOnMarketMutationLoading,
  } = useCommissionOnMarketAPI();
  const [currentStep, setCurrentStep] = useState(0);

  let navigate = useNavigate();

  const {
    watch,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    register,
    clearErrors,
    resetField,
    reset,
    control,
    formState: { errors },
  } = useForm<CommisionCampaignValues>({
    resolver: yupResolver(commissionCampaignSchema),
    defaultValues: defaultCommisionCampaignValues,
  });

  const campaignTypeIdValue = watch("campaignTypeId");

  const nextStep = async () => {
    setCurrentStep(prev => prev + 1);
  };
  const previousStep = async () => {
    setCurrentStep(prev => prev - 1);
  };

  useEffect(() => {
    if (commissionOnMarketMutationSuccess) {
      navigate("/campaigns");
    }
  }, [commissionOnMarketMutationSuccess]);

  const onSubmit = async (values: CommisionCampaignValues) => {
    await commissionOnMarketMutation({
      campaignTypeId: values.campaignTypeId,
      workspaceId: values.workspace.value,
      postcodeDistrictIds: values.postcodeDistricts.map(({ value }) => value),
      messageSetId: values.messageSetId,
      enabledOwnershipSourceIds: values.enabledOwnershipSources.map(({ value }) => value),
      automationLevelId: values.automationLevel.value,
      campaignName: values.campaignName,
      outreachAgentIds: values.outreachAgents.map(({ value }) => value),
      filters: {
        price: {
          min: values.filters.price.min.value,
          max: values.filters.price.max.value,
        },
        daysOnMarket: {
          min: values.filters.daysOnMarket.min.value,
          max: values.filters.daysOnMarket.max.value,
        },
        maximumAddresses: values.filters.maximumAddresses.value,
      },
    });
  };
  const scrolled = useScroll(15);

  return (
    <>
      <header
        className={cx(
          "fixed inset-x-0 top-0 isolate z-50 flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 transition-all md:grid md:grid-cols-[200px_auto_200px] md:px-6 dark:border-gray-900 dark:bg-gray-925",
          scrolled ? "h-12" : "h-20"
        )}
      >
        <div className="hidden flex-nowrap items-center gap-0.5 md:flex" aria-hidden="true">
          {/* <Logo
            className="w-7 p-px text-blue-500 dark:text-blue-500"
            aria-hidden="true"
          /> */}
          <span className="mt-0.5 text-lg font-semibold text-gray-900 dark:text-gray-50">
            Commission Campaign
          </span>
        </div>
        {/* <StepProgress steps={steps} />
        <Button variant="ghost" className="ml-auto w-fit" asChild>
          <a href="/reports">Skip to dashboard</a>
        </Button> */}
      </header>

      <form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 0 && (
          <IntentSignalSelection
            reset={reset}
            workspaces={activeWorkspaces}
            currentStep={currentStep + 1}
            campaignTypeId={campaignTypeIdValue}
            setValue={setValue}
            watch={watch}
            resetField={resetField}
            register={register}
            control={control}
            campaignTypes={campaignTypes}
            errors={errors}
            nextStep={nextStep}
            clearErrors={clearErrors}
            trigger={trigger}
          />
        )}
        {currentStep === 1 && (
          <OnMarketFilters
            postcodeDistricts={postcodeDistricts}
            currentStep={currentStep + 1}
            setValue={setValue}
            watch={watch}
            previousStep={previousStep}
            register={register}
            control={control}
            errors={errors}
            nextStep={nextStep}
            trigger={trigger}
          />
        )}
        {currentStep === 2 && (
          <Sequences
            messageSets={messageSets}
            currentStep={currentStep + 1}
            setValue={setValue}
            watch={watch}
            reset={reset}
            resetField={resetField}
            previousStep={previousStep}
            register={register}
            control={control}
            errors={errors}
            nextStep={nextStep}
            trigger={trigger}
          />
        )}
        {currentStep === 3 && (
          <Owners
            ownershipSources={ownershipSources}
            currentStep={currentStep + 1}
            setValue={setValue}
            watch={watch}
            previousStep={previousStep}
            register={register}
            control={control}
            errors={errors}
            nextStep={nextStep}
            trigger={trigger}
          />
        )}
        {currentStep === 4 && (
          <Automations
            currentStep={currentStep + 1}
            setValue={setValue}
            watch={watch}
            previousStep={previousStep}
            register={register}
            control={control}
            errors={errors}
            nextStep={nextStep}
            trigger={trigger}
          />
        )}
        {currentStep === 5 && (
          <CampaignName
            currentStep={currentStep + 1}
            setValue={setValue}
            resetField={resetField}
            watch={watch}
            previousStep={previousStep}
            register={register}
            control={control}
            errors={errors}
            nextStep={nextStep}
            trigger={trigger}
            commissionOnMarketMutationLoading={commissionOnMarketMutationLoading}
          />
        )}
      </form>
    </>
  );
};

export default CommissionCampaign;
