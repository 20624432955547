import { useLazyQuery } from "@apollo/client";
import { GetCampaignsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getCampaigns } from "../queries/getCampaigns";

export type UseGetCampaigns = ReturnType<typeof useGetCampaigns>;

export const useGetCampaigns = () => {
  const [fetchCampaigns, { data, loading, error }] = useLazyQuery<GetCampaignsQuery>(getCampaigns);

  const campaigns =
    data?.campaign && Array.isArray(data?.campaign)
      ? data?.campaign.map(campaign => {
          const numberOfSyncedProfile =
            Array.isArray(campaign.synced_leads_count) &&
            campaign.synced_leads_count[0] &&
            campaign.synced_leads_count[0].number_of_leads
              ? campaign.synced_leads_count[0].number_of_leads
              : 0;
          const totalNumberOfCampaignLeadProfiles =
            Array.isArray(campaign.all_leads_count) &&
            campaign.all_leads_count[0] &&
            campaign.all_leads_count[0].number_of_leads
              ? campaign.all_leads_count[0].number_of_leads
              : 1; // using 1 as cannot divide by 0
          return {
            id: campaign.id,
            name: campaign.name,
            workspace: campaign.workspace?.name,
            outreachAgentPlatformIdentifier: campaign.outreach_agent?.platform_identifier,
            campaignType: campaign.campaign_type?.name,
            campaignInternalStatus: campaign.campaign_internal_status?.name,
            campaignExternalStatus: campaign.campaign_status?.name,
            syncRatio: numberOfSyncedProfile / totalNumberOfCampaignLeadProfiles,
          };
        })
      : [];

  return {
    campaigns,
    campaignsCount: data?.campaign_aggregate.aggregate?.count ?? 0,
    campaignsError: error,
    campaignsLoading: loading,
    fetchCampaigns,
  };
};
