import { Boxes, House, Inbox, Send, SwatchBook, User } from "lucide-react";

export const navigation = [
  { name: "Overview", path: "/analytics", icon: House },
  {
    name: "Conversations",
    path: "/conversations",
    icon: Inbox,
  },
  {
    name: "Workspaces",
    path: "/workspaces",
    icon: Boxes,
  },
  {
    name: "Campaigns",
    path: "/campaigns",
    icon: SwatchBook,
  },
  {
    name: "Sequences",
    path: "/sequences",
    icon: Send,
  },
  {
    name: "Get owners",
    path: "/get-owners",
    icon: User,
  },
] as const;

export const orgName = "MeetMore";
