import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormReset,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { CommisionCampaignValues } from "../../validation";
import { UseMessageSets } from "../../../../../../shared/domains/messageSets/graphql/hooks/useMessageSets";

interface EditingMessageSetProps {
  setValue: UseFormSetValue<CommisionCampaignValues>;
  errors: FieldErrorsImpl<DeepRequired<CommisionCampaignValues>>;
  messageSets: UseMessageSets["messageSets"];
  previousStep: () => void;
  validateAndNext: () => void;
  messageSetId: number;
  resetField: UseFormResetField<CommisionCampaignValues>;
  reset: UseFormReset<CommisionCampaignValues>;
  watch: UseFormWatch<CommisionCampaignValues>;
}
const ViewingMessageSet = ({
  errors,
  setValue,
  messageSets,
  messageSetId,
  reset,
  resetField,
  watch,
  previousStep,
  validateAndNext,
}: EditingMessageSetProps) => {
  const selectedMessageSet = messageSets.find(messageSet => messageSet.id === messageSetId);
  const values = watch();

  return (
    <div className="max-w-3xl mx-auto">
      <div className="shadow sm:rounded-md">
        <div className="space-y-6 bg-white mt-5 px-4 ">
          <div className="w-full">
            <div className="border-b border-gray-100 bg-white  py-5 ">
              <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Template:{" "}
                    <span className="ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-600/20">
                      {selectedMessageSet?.name}
                    </span>
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    The below messages will be sent from your Channels
                  </p>
                </div>

                <div className="ml-4 mt-4 flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 flex-shrink-0">
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        reset({ ...values, messageSetId: undefined });
                      }}
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    >
                      <span>Remove Sequence</span>
                    </button>
                  </>
                </div>
              </div>
            </div>

            <ul role="list" className="divide-y divide-gray-100">
              {selectedMessageSet?.messages.map((message, index) => (
                <li key={index} className="flex justify-between gap-x-6 py-5">
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        Message {message.msg_order}
                      </p>
                      <p className="mt-1 text-xs leading-5 text-gray-500">{message.content}</p>
                    </div>
                  </div>
                  <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                    {index !== 0 && (
                      <span className="inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800">
                        {message.send_after_days} day(s) later
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="py-3 mt-4">
        <div className="flex space-x-6">
          <button
            type="button"
            onClick={() => previousStep()}
            className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => validateAndNext()}
            className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewingMessageSet;
