import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";
import { UpdateCampaignValues } from "../../../../../modules/campaigns/campaigns/validation";

export const updateCampaignAPI = async ({
  externalStatusId,
  name,
  id,
  workspaceId,
}: UpdateCampaignValues) => {
  const payload = {
    campaignName: name,
    workspaceId,
    externalCampaignStatusId: externalStatusId,
  };

  const response = await meetmoreAPI.patch(`/campaigns/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useUpdateCampaignAPI = () => {
  let loadingToastId: string;

  return useMutation(updateCampaignAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Updating campaign...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Campaign updated!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
