import { gql } from "@apollo/client";

export const getPostcodeDistricts = gql`
  query getPostcodeDistricts {
    postcode_district {
      id
      code
    }
  }
`;
