import { gql } from "@apollo/client";

export const getCampaigns = gql`
  query getCampaigns($internalStatusIds: [Int!],$workspacesIds: [Int!], $offset: Int!, $limit: Int!, $orderBy: [campaign_order_by!]) {
    campaign(
      where: { 
        campaign_internal_status: {
          id: {
            _in: $internalStatusIds
          }
        }
        workspace_id: { _is_null: false, _in: $workspacesIds }, outreach_agent_id: { _is_null: false } 
      }
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
      name
      workspace {
        name
      }
      outreach_agent {
        platform_identifier
      }
      campaign_type {
        name
      }
      campaign_internal_status {
        name
      }
      campaign_status { 
        name
      }

      synced_leads_count {
        number_of_leads
      }
      
      all_leads_count{
        number_of_leads
      } 
    }
    campaign_aggregate(
      where: { 
        campaign_internal_status: {
          id: {
            _in: $internalStatusIds
          }
        }
        workspace_id: { _is_null: false, _in: $workspacesIds }, outreach_agent_id: { _is_null: false } 
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
