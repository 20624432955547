import { gql } from "@apollo/client";

export const getAddressesByPostcodeUnitIds = gql`
  query getAddressesByPostcodeUnitIds($postcodeUnitIds: [Int!]) {
    address(where: { postcode_unit_id: { _in: $postcodeUnitIds } }) {
      id
      full_address
      uprn
      postcode_unit {
        id
        code
      }
    }
  }
`;
