import { useEffect } from "react";
import { Button } from "../../../../shared/components/button/Button";
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../../../../shared/components/drawer/Drawer";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { Controller, useForm } from "react-hook-form";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { UseGetCampaignById } from "../../../../shared/domains/campaigns/graphql/hooks/useGetCampaignById";
import { UseWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { updateCampaignSchema, UpdateCampaignValues } from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseCampaignExternalStatuses } from "../../../../shared/domains/static/graphql/hooks/useCampaignExternalStatuses";

interface DataTableDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: UseGetCampaignById["campaign"];
  workspaces: UseWorkspaces["workspaces"];
  campaignStatuses: UseCampaignExternalStatuses["campaignExternalStatuses"];
  onUpdateCampaign: (data: UpdateCampaignValues) => Promise<void>;
}

export type Values = {
  name: string;
  workspaceId: string;
  externalStatusId: string;
};

export function DataTableDrawer({
  open,
  onOpenChange,
  data,
  workspaces,
  campaignStatuses,
  onUpdateCampaign,
}: DataTableDrawerProps) {
  const {
    handleSubmit,
    register,
    setValue,
    control: updateCampaignControl,
    formState: { errors: updateCampaignErrors },
  } = useForm<Values>({
    resolver: yupResolver(updateCampaignSchema),
    defaultValues: {},
  });

  const handleUpdateCampaign = async (payload: Values) => {
    await onUpdateCampaign({
      id: data?.id as number,
      workspaceId: Number(payload.workspaceId),
      externalStatusId: Number(payload.externalStatusId),
      name: payload.name,
    });
  };

  useEffect(() => {
    setValue("externalStatusId", data.statusId?.toString() || "");
    setValue("name", data.name || "");
    setValue("workspaceId", data.workspaceId?.toString() || "");
  }, [data]);

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {data ? (
        <DrawerContent className="flex flex-col h-full  max-h-[98vh] overflow-x-hidden sm:max-w-lg dark:bg-gray-925">
          <DrawerHeader className="-px-6 w-full">
            <DrawerTitle className="flex w-full items-center justify-between">
              <span>Edit Campaign</span>
            </DrawerTitle>
          </DrawerHeader>
          <form onSubmit={handleSubmit(handleUpdateCampaign)} className="flex flex-col h-full">
            <DrawerBody className="flex-grow overflow-y-auto -mx-6 space-y-4 px-6 pb-6">
              <div>
                <Label htmlFor="name" className="font-medium">
                  Campaign Name
                </Label>
                <Input
                  {...register("name")}
                  type="text"
                  defaultValue={data.name}
                  className="mt-2"
                />
                {updateCampaignErrors.name && (
                  <ErrorMessage>{updateCampaignErrors.name.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Label htmlFor="role-new-user" className="font-medium">
                  Workspace
                </Label>
                <Controller
                  control={updateCampaignControl}
                  name="workspaceId"
                  defaultValue={data.workspaceId?.toString()}
                  render={({ field }) => {
                    return (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger id="workspace" name="workspace" className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent align="end">
                          {workspaces.map(workspace => (
                            <SelectItem key={workspace.id} value={workspace.id?.toString()}>
                              {workspace.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    );
                  }}
                ></Controller>
                {updateCampaignErrors.workspaceId && (
                  <ErrorMessage>{updateCampaignErrors.workspaceId.message}</ErrorMessage>
                )}
              </div>
              <div>
                <Label htmlFor="role-new-user" className="font-medium">
                  Campaign Status
                </Label>
                <Controller
                  control={updateCampaignControl}
                  name="externalStatusId"
                  defaultValue={data.statusId?.toString()}
                  render={({ field }) => {
                    return (
                      <Select onValueChange={field.onChange} {...field}>
                        <SelectTrigger
                          id="externalStatusId"
                          name="externalStatusId"
                          className="mt-2"
                        >
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent align="end">
                          {campaignStatuses.map(campaignStatus => (
                            <SelectItem
                              key={campaignStatus.id}
                              value={campaignStatus.id?.toString()}
                            >
                              {campaignStatus.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    );
                  }}
                ></Controller>
                {updateCampaignErrors.externalStatusId && (
                  <ErrorMessage>{updateCampaignErrors.externalStatusId.message}</ErrorMessage>
                )}
              </div>
            </DrawerBody>
            <DrawerFooter className="flex-shrink-0 -mx-6 -mb-2 gap-2 bg-white px-6 dark:bg-gray-925">
              <DrawerClose>
                <Button type="button" variant="secondary" className="w-full">
                  Cancel
                </Button>
              </DrawerClose>

              <Button type="submit" className="w-full sm:max-w-[4.5rem]">
                Submit
              </Button>
            </DrawerFooter>
          </form>
        </DrawerContent>
      ) : null}
    </Drawer>
  );
}
