import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../infrastructure/firebase";
import { useIsUserAdmin } from "../domains/users/graphql/hooks/isUserAdmin";
import AccessDenied from "../../modules/authentication/accessDenied/page/AccessDenied";

interface AdminRouteProps {
  element: ReactElement;
}

const AdminRoute = ({ element }: AdminRouteProps) => {
  const [user, loading] = useAuthState(auth);
  const { isAdmin, loading: isUserAdminLoading } = useIsUserAdmin({ externalId: user?.uid || "" });

  if (loading || isUserAdminLoading) {
    return null; // Or a loading spinner
  }

  if (user && isAdmin) {
    return element;
  } else if (user && !isAdmin) {
    return <AccessDenied />;
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminRoute;
