import { RadioGroup, Switch } from "@headlessui/react";
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  UseFormSetValue,
  UseFormTrigger,
  UseFormRegister,
  FieldErrorsImpl,
  DeepRequired,
  UseFormWatch,
  UseFormResetField,
  Controller,
  Control,
} from "react-hook-form";
import { CommisionCampaignValues } from "../validation";
import ErrorMessage from "../../../../../shared/components/errorMessage/ErrorMessage";

interface CampaignNameProps {
  register: UseFormRegister<CommisionCampaignValues>;
  setValue: UseFormSetValue<CommisionCampaignValues>;
  errors: FieldErrorsImpl<DeepRequired<CommisionCampaignValues>>;
  nextStep: () => Promise<void>;
  previousStep: () => Promise<void>;
  trigger: UseFormTrigger<CommisionCampaignValues>;
  watch: UseFormWatch<CommisionCampaignValues>;
  resetField: UseFormResetField<CommisionCampaignValues>;
  control: Control<CommisionCampaignValues, any>;
  currentStep: number;
  commissionOnMarketMutationLoading: boolean;
}

const CampaignName = ({
  previousStep,
  setValue,
  errors,
  register,
  trigger,
  nextStep,
  control,
  resetField,
  currentStep,
  watch,
  commissionOnMarketMutationLoading,
}: CampaignNameProps) => {
  return (
    <>
      <div className="bg-gray-50 pb-20 min-h-screen h-full flex-wrap flex flex-col items-center justify-center focus:outline-none">
        <div className="relative w-full mx-auto px-8 pt-10 pb-8 md:px-10 xl:px-12">
          <div className=" max-w-3xl mx-auto space-y-8">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {currentStep}. <span className="text-orange-500">Name</span> your campaign.
              </h2>
              <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-orange-700">
                      This reference will be used to identify your campaign in the future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-3xl mx-auto">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white mt-5 px-4 py-5 sm:p-6">
                  <div className="w-full">
                    <div>
                      <div className=" flex flex-row justify-between items-center">
                        <div className="flex flex-col">
                          <label className="block text-sm font-medium text-gray-700">
                            Campaign Name
                          </label>
                          <span className="text-xs text-gray-400">
                            Please set your campaign name
                          </span>
                        </div>
                      </div>
                      <div className="mt-2">
                        <input
                          {...register("campaignName")}
                          placeholder="e.g. 20/20 - Alvin Towers`"
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                        />
                      </div>
                      {errors.campaignName && (
                        <ErrorMessage children={errors.campaignName.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 mt-4">
              <div className="flex space-x-6">
                <button
                  type="button"
                  onClick={() => previousStep()}
                  className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={commissionOnMarketMutationLoading}
                  className="w-full inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Submit Commission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignName;
