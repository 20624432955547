import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export type GetSuspectedOwnersPayload = {
  email: string;
  propertyTitles: string[];
  uprn: string;
};

export const getSuspectedOwnersAPI = async (data: GetSuspectedOwnersPayload) => {
  const response = await meetmoreAPI.post(`/suspected-owners`, data, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });

  return response.data;
};

export const useGetSuspectedOwnersAPI = () => {
  let loadingToastId: string;

  return useMutation(getSuspectedOwnersAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Getting Suspected Owners...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Got suspected owners and sent an email to the user");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
