import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface UpdateOutreachAgentBody {
  outreachAgentId: number;
  outreachAgentTypeId: number;
  outreachPlatformId: number;
  externalId: number;
  externalCredentialId?: string;
  providerId: number;
  platformIdentifier: string;
  isActive: boolean;
  workspaceId: number;
}

export const updateOutreachAgentAPI = async ({
  outreachAgentId,
  outreachAgentTypeId,
  outreachPlatformId,
  externalId,
  externalCredentialId,
  providerId,
  platformIdentifier,
  isActive,
  workspaceId,
}: UpdateOutreachAgentBody) => {
  const payload = {
    outreachAgentTypeId,
    outreachPlatformId,
    externalId,
    externalCredentialId,
    providerId,
    platformIdentifier,
    isActive,
    workspaceId,
  };

  const response = await meetmoreAPI.patch(`/outreach-agents/${outreachAgentId}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useUpdateOutreachAgentAPI = () => {
  let loadingToastId: string;

  return useMutation(updateOutreachAgentAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Updating Channel...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Channel updated!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
