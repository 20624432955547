import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface UpdateWorkspaceBody {
  workspaceId: number;
  workspaceName: string;
  workspaceStatusId: number;
}

export const updateWorkspaceAPI = async ({
  workspaceId,
  workspaceName,
  workspaceStatusId,
}: UpdateWorkspaceBody) => {
  const payload = {
    workspaceName,
    workspaceStatusId,
  };

  const response = await meetmoreAPI.patch(`/workspaces/${workspaceId}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useUpdateWorkspaceAPI = () => {
  let loadingToastId: string;

  return useMutation(updateWorkspaceAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Updating workspace...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Workspace updated!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
