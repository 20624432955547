import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { Badge, BadgeProps } from "../../../../shared/components/badge/Badge";

import { UseWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { WORKSPACE_STATUS } from "../../../../shared/constants/models";
import { ArrayElement } from "../../../../shared/utils";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";

const columnHelper = createColumnHelper<UseWorkspaces["workspaces"]>();

export const getColumns = () =>
  [
    columnHelper.accessor("name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Name" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Name",
      },
    }),
    columnHelper.accessor("workspace_status_id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Status" />,
      enableSorting: true,
      meta: {
        className: "text-left",
        displayName: "Status",
      },
      cell: ({ row }) => {
        const statusValue = row.getValue("workspace_status_id");
        const activeStatus = statusValue === WORKSPACE_STATUS.ACTIVE;
        return (
          <Badge variant={activeStatus ? "success" : "error"}>
            {activeStatus ? "Active" : "Inactive"}
          </Badge>
        );
      },
    }),
  ] as ColumnDef<ArrayElement<UseWorkspaces["workspaces"]>>[];
