import { Button } from "../../../../shared/components/button/Button";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { useWorkspaceStatuses } from "../../../../shared/domains/static/graphql/hooks/useWorkspaceStatuses";
import {
  editWorkspaceBlacklistedAgents,
  EditWorkspaceBlacklistedAgentsValues,
  updateWorkspaceValidationSchema,
  UpdateWorkspaceValues,
} from "../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useWorkspaceById } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaceById";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useEffect, useState } from "react";
import { useUpdateWorkspaceAPI } from "../../../../shared/domains/workspaces/REST/updateWorkspaceAPI";
import { Divider } from "../../../../shared/components/divider/Divider";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Plus, Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "../../../../shared/components/table/Table";
import { reactSelectStyling } from "../../../../shared/styles";
import {
  addOrRemoveBlacklistedAgentsAPI,
  useAddOrRemoveBlacklistedAgents,
} from "../../../../shared/domains/workspaces/REST/addOrRemoveBlacklistedAgentAPI";
import { useBranchAgentsLazy } from "../../../../shared/domains/branchAgents/graphql/hooks/useBranchAgents";
import ReactSelect from "react-select";

export default function SingleWorkspaceGeneral() {
  // params
  let { workspaceId } = useParams<{ workspaceId: string }>();
  const [isBlacklistedAgentDialogOpen, setIsBlacklistedAgentDialogOpen] = useState(false);

  // non lazy hooks
  const { workspaceStatuses } = useWorkspaceStatuses();
  const { workspace, workspaceLoading, refetchWorkspace } = useWorkspaceById({
    id: Number(workspaceId),
  });

  // lazy hooks

  const { branchAgents, fetchBranchAgents } = useBranchAgentsLazy();

  // api mutations

  const {
    mutateAsync: updateWorkspaceMutation,
    isSuccess: updateWorkspaceMutationSuccess,
    isLoading: updateWorkspaceMutationLoading,
  } = useUpdateWorkspaceAPI();

  const {
    mutateAsync: addBlacklistedAgentMutation,
    isSuccess: addBlacklistedAgentMutationSuccess,
    isLoading: addBlacklistedAgentMutationLoading,
  } = useAddOrRemoveBlacklistedAgents();

  // use effects

  useEffect(() => {
    if (updateWorkspaceMutationSuccess || addBlacklistedAgentMutationSuccess) {
      refetchWorkspace();
    }
  }, [updateWorkspaceMutationSuccess, addBlacklistedAgentMutationSuccess]);

  useEffect(() => {
    if (!workspaceLoading && !updateWorkspaceMutationLoading && workspace) {
      reset({
        workspaceStatusId: String(workspace.workspace_status_id),
        name: workspace.name,
      });
    }
  }, [workspace, updateWorkspaceMutationLoading, workspaceLoading]);

  useEffect(() => {
    if (addBlacklistedAgentMutationSuccess) {
      setIsBlacklistedAgentDialogOpen(false);
      resetBlacklistedAgents(undefined);
    }
  }, [addBlacklistedAgentMutationSuccess]);

  // form related

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UpdateWorkspaceValues>({
    resolver: yupResolver(updateWorkspaceValidationSchema),
    defaultValues: workspace
      ? {
          workspaceStatusId: String(workspace?.workspace_status_id),
          name: workspace?.name,
        }
      : undefined,
  });

  const submitInformationUpdate = (data: UpdateWorkspaceValues) => {
    updateWorkspaceMutation({
      workspaceId: Number(workspaceId),
      workspaceName: data.name,
      workspaceStatusId: Number(data.workspaceStatusId),
    });
  };

  const onBlacklistedAgentSubmit = async (values: EditWorkspaceBlacklistedAgentsValues) => {
    await addBlacklistedAgentMutation({
      workspaceId: Number(workspaceId),
      branchAgentIds: values.branchAgents.map(agent => agent.value),
      parentAgentIds: [],
      action: "addExclusions",
    });
  };

  const {
    handleSubmit: handleSubmitBlacklistedAgents,
    control: controlBlacklistedAgents,
    reset: resetBlacklistedAgents,
    formState: { errors: blacklistedAgentsErrors },
  } = useForm<EditWorkspaceBlacklistedAgentsValues>({
    resolver: yupResolver(editWorkspaceBlacklistedAgents),
  });

  return (
    <>
      <div className="space-y-10 pb-20">
        <section aria-labelledby="workspace-information">
          <form onSubmit={handleSubmit(submitInformationUpdate)}>
            <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
              <div>
                <h2
                  id="workspace-information"
                  className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
                >
                  Workspace information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Manage workspace information.
                </p>
              </div>
              <div className="md:col-span-2">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="col-span-full sm:col-span-3">
                    <Label htmlFor="name" className="font-medium">
                      Name
                    </Label>
                    <Input
                      type="text"
                      {...register("name")}
                      placeholder="Agency"
                      className="mt-2"
                    />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </div>

                  <div className="col-span-full sm:col-span-3">
                    <Label htmlFor="Workspace-status" className="font-medium">
                      Status
                    </Label>
                    <Controller
                      control={control}
                      name="workspaceStatusId"
                      render={({ field }) => {
                        return (
                          <Select onValueChange={field.onChange} {...field}>
                            <SelectTrigger id="Workspace-status" className="mt-2 w-full">
                              <SelectValue placeholder="Workspace Status" />
                            </SelectTrigger>
                            <SelectContent>
                              {workspaceStatuses.map(item => (
                                <SelectItem key={item.id} value={item.id?.toString()}>
                                  {item.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        );
                      }}
                    ></Controller>
                    {errors.workspaceStatusId && (
                      <ErrorMessage>{errors.workspaceStatusId.message}</ErrorMessage>
                    )}
                  </div>

                  <div className="col-span-full mt-6 flex justify-end">
                    <Button type="submit" disabled={updateWorkspaceMutationLoading}>
                      Update Information
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <Divider />
        <section aria-labelledby="payment-method-heading">
          <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
            <div>
              <h2
                id="payment-method-heading"
                className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
              >
                BlackListed Agents
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Addresses Listed by the following Agents will be automatically removed from
                campaigns
              </p>
            </div>
            <div className="md:col-span-2">
              <div className="flex items-center justify-between">
                <h3
                  id="cards-heading"
                  className="text-sm font-semibold text-gray-900 dark:text-gray-50"
                >
                  Agents
                </h3>
                <Dialog
                  open={isBlacklistedAgentDialogOpen}
                  onOpenChange={open => {
                    if (open) {
                      fetchBranchAgents();
                    }
                    setIsBlacklistedAgentDialogOpen(open);
                  }}
                >
                  <DialogTrigger asChild>
                    <Button className="gap-2">
                      <Plus className="-ml-1 size-4 shrink-0" aria-hidden="true" />
                      Add Agent
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-lg">
                    <DialogHeader>
                      <DialogTitle>Add Blacklisted Agent</DialogTitle>
                      <DialogDescription className="mt-1 text-sm leading-6">
                        Select one or more agents
                      </DialogDescription>
                    </DialogHeader>
                    <form
                      onSubmit={handleSubmitBlacklistedAgents(onBlacklistedAgentSubmit)}
                      className="mt-4 space-y-4"
                    >
                      <div>
                        <Label htmlFor="cardName" className="font-medium">
                          Agent
                        </Label>
                        <Controller
                          name="branchAgents"
                          control={controlBlacklistedAgents}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              options={branchAgents
                                .filter(
                                  agent =>
                                    !workspace?.workspace_branch_agent_blacklists.some(
                                      blacklistedAgent =>
                                        blacklistedAgent.branch_agent.id === agent.id
                                    )
                                )
                                .map(agent => {
                                  return {
                                    label: agent.name,
                                    value: agent.id,
                                  };
                                })}
                              isSearchable
                              isMulti
                              placeholder="Select Branch Agent"
                            />
                          )}
                        />
                        {blacklistedAgentsErrors.branchAgents && (
                          <ErrorMessage>Please select at least 1 branch agent</ErrorMessage>
                        )}
                      </div>

                      <DialogFooter className="mt-6">
                        <DialogClose asChild>
                          <Button className="mt-2 w-full sm:mt-0 sm:w-fit" variant="secondary">
                            Cancel
                          </Button>
                        </DialogClose>
                        <Button
                          disabled={addBlacklistedAgentMutationLoading}
                          className="w-full gap-2 sm:w-fit"
                          variant="primary"
                          type="submit"
                        >
                          Add Agent
                        </Button>
                      </DialogFooter>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <TableRoot className="mt-4" aria-labelledby="cards-heading">
                <Table>
                  <TableHead>
                    <TableRow className="border-b border-gray-200 dark:border-gray-800">
                      <TableHeaderCell className="text-xs font-medium uppercase">
                        Branch Agent
                      </TableHeaderCell>
                      <TableHeaderCell className="text-xs font-medium uppercase">
                        Parent Agent
                      </TableHeaderCell>
                      <TableHeaderCell className="text-xs font-medium uppercase">
                        Portal
                      </TableHeaderCell>

                      <TableHeaderCell className="text-right text-xs font-medium uppercase">
                        <span className="sr-only">Edit</span>
                      </TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workspace && workspace?.workspace_branch_agent_blacklists.length > 0 ? (
                      workspace.workspace_branch_agent_blacklists.map(blacklistedAgent => (
                        <TableRow key={blacklistedAgent.branch_agent.id}>
                          <TableCell className="py-2.5">
                            {blacklistedAgent.branch_agent.name}
                          </TableCell>
                          <TableCell className="py-2.5">
                            {blacklistedAgent.branch_agent?.parent_agent?.name}
                          </TableCell>
                          <TableCell className="py-2.5">
                            {blacklistedAgent.branch_agent?.agent_provider.name}
                          </TableCell>
                          <TableCell>
                            <div>
                              <Button
                                variant="ghost"
                                disabled={addBlacklistedAgentMutationLoading}
                                onClick={() => {
                                  addBlacklistedAgentMutation({
                                    workspaceId: Number(workspaceId),
                                    branchAgentIds: [blacklistedAgent.branch_agent.id],
                                    parentAgentIds: [],
                                    action: "removeExclusions",
                                  });
                                }}
                                className="aspect-square p-3 text-gray-600 hover:border hover:border-gray-300 hover:bg-gray-50 hover:text-rose-500 sm:p-2.5 dark:text-gray-400 hover:dark:border-gray-800 hover:dark:bg-gray-900 hover:dark:text-rose-500"
                              >
                                <Trash2 className="size-4 shrink-0" aria-hidden="true" />
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} className="h-24 text-center">
                          No results.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableRoot>
            </div>
          </div>
        </section>
        {/* <Divider />
        <section aria-labelledby="notification-settings">
          <form>
            <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-3">
              <div>
                <h2
                  id="notification-settings"
                  className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
                >
                  Notification settings [NOT HOOKED UP]
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Configure the types of notifications this workspace will receive
                </p>
              </div>
              <div className="md:col-span-2">
                <fieldset>
                  <legend className="text-sm font-medium text-gray-900 dark:text-gray-50">
                    Team
                  </legend>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    Configure the types of team alerts you want to receive.
                  </p>
                  <ul role="list" className="mt-4 divide-y divide-gray-200 dark:divide-gray-800">
                    <li className="flex items-center gap-x-3 py-3">
                      <Checkbox id="team-requests" name="team-requests" defaultChecked />
                      <Label htmlFor="team-requests">Team join requests</Label>
                    </li>
                    <li className="flex items-center gap-x-3 py-3">
                      <Checkbox id="team-activity-digest" />
                      <Label htmlFor="team-activity-digest">Weekly team activity digest</Label>
                    </li>
                  </ul>
                </fieldset>
                <fieldset className="mt-6">
                  <legend className="text-sm font-medium text-gray-900 dark:text-gray-50">
                    Usage
                  </legend>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    Configure the types of usage alerts you want to receive.
                  </p>
                  <ul role="list" className="mt-4 divide-y divide-gray-200 dark:divide-gray-800">
                    <li className="flex items-center gap-x-3 py-3">
                      <Checkbox id="api-requests" name="api-requests" />
                      <Label htmlFor="api-requests">API incidents</Label>
                    </li>
                    <li className="flex items-center gap-x-3 py-3">
                      <Checkbox id="workspace-execution" name="workspace-execution" />
                      <Label htmlFor="workspace-execution">Platform incidents</Label>
                    </li>
                    <li className="flex items-center gap-x-3 py-3">
                      <Checkbox id="query-caching" name="query-caching" defaultChecked />
                      <Label htmlFor="query-caching">Payment transactions</Label>
                    </li>
                    <li className="flex items-center gap-x-3 py-3">
                      <Checkbox id="storage" name="storage" defaultChecked />
                      <Label htmlFor="storage">User behavior</Label>
                    </li>
                  </ul>
                </fieldset>
                <div className="col-span-full mt-6 flex justify-end">
                  <Button type="submit">Save settings</Button>
                </div>
              </div>
            </div>
          </form>
        </section>
        <Divider /> */}
      </div>
    </>
  );
}
