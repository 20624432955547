import { getOutreachAgentTypes } from "../queries/getOutreachAgentTypes";
import { GetOutreachAgentTypesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseOutreachAgentTypes = ReturnType<typeof useOutreachAgentTypes>;

export const useOutreachAgentTypes = () => {
  const { data, loading, error } = useQuery<GetOutreachAgentTypesQuery>(getOutreachAgentTypes);
  return {
    outreachAgentTypes:
      data && Array.isArray(data.outreach_agent_type) ? data.outreach_agent_type : [],
    outreachAgentTypesError: error,
    outreachAgentTypesLoading: loading,
  };
};
