import { Sidebar } from "../../../../shared/navigation/components/Sidebar";
import { cx } from "../../../../shared/utils";
import {
  TabNavigation,
  TabNavigationLink,
} from "../../../../shared/components/tabNavigation/TabNavigation";
import { Link, useLocation, useParams } from "react-router-dom";
import React from "react";
import { singleWorkspaceNavigation } from "../data";
import { useWorkspaceById } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaceById";

export default function SingleWorkspaceLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const location = useLocation();
  const pathname = location.pathname;

  let { workspaceId } = useParams<{ workspaceId: string }>();
  const { workspace } = useWorkspaceById({ id: Number(workspaceId) });

  return (
    <div className="mx-auto max-w-screen-2xl">
      <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      <div
        className={cx(
          isCollapsed ? "lg:pl-[60px]" : "lg:pl-64",
          "ease transform-gpu overflow-x-hidden transition-all duration-100 will-change-transform lg:bg-gray-50 lg:py-3 lg:pr-3 lg:dark:bg-gray-950"
        )}
      >
        <div className=" bg-white p-4 sm:p-6 lg:rounded-lg lg:border lg:border-gray-200 dark:bg-gray-925 lg:dark:border-gray-900">
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-50">
            {workspace?.name}
          </h1>
          <TabNavigation className="mt-6">
            {singleWorkspaceNavigation.map(item => (
              <TabNavigationLink
                key={item.name}
                asChild
                active={pathname.toLowerCase().includes(`workspaces/${workspaceId}${item.path}`)}
                className="px-5"
              >
                <Link to={`/workspaces/${workspaceId}${item.path}`}>{item.name}</Link>
              </TabNavigationLink>
            ))}
          </TabNavigation>
          <div className="pt-6">{children}</div>
        </div>
      </div>
    </div>
  );
}
