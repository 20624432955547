import { gql } from "@apollo/client";

export const getOutreachAgentsByWorkspaceId = gql`
  query getOutreachAgentsByWorkspaceId($id: Int!) {
    outreach_agent(where: { workspace_id: { _eq: $id } }) {
      id
      outreach_agent_type { 
        name
        id
      }
      outreach_platform {
        name
        id
      }
      external_id
      created_at 
      campaign_external_connection_provider {
        id
        name
      }
      platform_identifier
      external_credential_id
      is_active
      workspace_id
      is_verified
    }
  }
`;
