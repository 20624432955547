import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  InformationCircleIcon,
  MagnifyingGlassCircleIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";

import {
  UseFormSetValue,
  UseFormRegister,
  UseFormWatch,
  FieldErrorsImpl,
  UseFormTrigger,
  DeepRequired,
  Controller,
  Control,
} from "react-hook-form";
import { UseMutateAsyncFunction } from "react-query";
import { LoaderIcon } from "react-hot-toast";

import Select from "react-select";
import { CommisionCampaignValues } from "../validation";
import { UsePostcodeDistricts } from "../../../../../shared/domains/postcodes/graphql/hooks/usePostcodeDistricts";
import { reactSelectStyling } from "../../../../../shared/styles";
import { automationOptions, daysOnMarketOptions, maximumAddresses, priceOptions } from "../data";
import ErrorMessage from "../../../../../shared/components/errorMessage/ErrorMessage";
import { UseOwnershipSources } from "../../../../../shared/domains/static/graphql/hooks/useOwnershipSources";

interface AutomationsProps {
  nextStep: () => Promise<void>;
  previousStep: () => Promise<void>;
  register: UseFormRegister<CommisionCampaignValues>;
  setValue: UseFormSetValue<CommisionCampaignValues>;
  errors: FieldErrorsImpl<DeepRequired<CommisionCampaignValues>>;
  watch: UseFormWatch<CommisionCampaignValues>;
  control: Control<CommisionCampaignValues, any>;
  trigger: UseFormTrigger<CommisionCampaignValues>;
  currentStep: number;
}

export default function Automations({
  previousStep,
  watch,
  setValue,
  register,
  errors,
  currentStep,
  trigger,
  control,
  nextStep,
}: AutomationsProps) {
  const validateAndNext = async () => {
    const results = await trigger(["automationLevel"]);
    if (results) {
      nextStep();
    }
  };

  return (
    <div className="bg-gray-50 pb-20  min-h-screen h-full flex-wrap flex flex-col items-center justify-center focus:outline-none">
      <div className="relative w-full mx-auto px-8 pt-10 pb-8 md:px-10 xl:px-12">
        <div className=" max-w-3xl mx-auto space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {currentStep}. Which <span className="text-orange-500">Automation Level</span> should
              this campaign have?
            </h2>
            <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-orange-700">
                    Automation Level determines how much of the campaign is automated
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:rounded-md">
              <div className="space-y-6 bg-white mt-5 px-4 py-5 sm:p-6">
                <div className="w-full">
                  {/* here*/}

                  <div>
                    <div className=" flex flex-row justify-between items-center">
                      <div className="flex flex-row justify-between items-center w-full ">
                        <div className="flex flex-col">
                          <label className="block mt-5 text-sm font-medium text-gray-700">
                            Automation Level
                          </label>
                          <span className="text-gray-500 text-xs">
                            Please select the stage of automation for this campaign
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 flex flex-col rounded-md shadow-sm">
                      <div className="w-full ">
                        <Controller
                          name="automationLevel"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              options={automationOptions.map(level => {
                                return {
                                  label: level.label,
                                  value: level.id,
                                };
                              })}
                              isSearchable
                              placeholder="Select Automation Level"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  {errors.automationLevel && (
                    <ErrorMessage>Please select an Automation Level</ErrorMessage>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="py-3 mt-4">
            <div className="flex space-x-6">
              <button
                type="button"
                onClick={() => previousStep()}
                className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Back
              </button>
              <button
                type="button"
                onClick={() => validateAndNext()}
                className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
