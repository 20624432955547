import { gql } from "@apollo/client";

export const getAdminUserByExternalId = gql`
  query getAdminUserByExternalId($externalId: String!) {
    workspace_user(
      where: { user: { external_id: { _eq: $externalId } }, workspace: { is_admin: { _eq: true } } }
    ) {
      user_id
    }
  }
`;
