import { useQuery } from "@apollo/client";
import { getWorkspacesPaginated } from "../queries/getWorkspacesPaginated";
import { GetWorkspacesPaginatedQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { WORKSPACE_STATUS } from "../../../../constants/models";

export type UseWorkspacesPaginated = ReturnType<typeof useWorkspacesPaginated>;

export const useWorkspacesPaginated = () => {
  const { data, loading, error, refetch } =
    useQuery<GetWorkspacesPaginatedQuery>(getWorkspacesPaginated);

  return {
    workspaces: data && Array.isArray(data.workspace) ? data.workspace : [],
    workspacesCount: data?.workspace_aggregate?.aggregate?.count || 0,
    activeWorkspaces:
      data && Array.isArray(data.workspace)
        ? data.workspace.filter(
            workspace => workspace.workspace_status_id === WORKSPACE_STATUS.ACTIVE
          )
        : [],
    workspacesError: error,
    workspacesLoading: loading,
    refetchWorkspaces: refetch,
  };
};
