import dayjs from "dayjs";
import { Badge } from "../../../../shared/components/badge/Badge";
import { Button } from "../../../../shared/components/button/Button";
import { Card } from "../../../../shared/components/card/Card";
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "../../../../shared/components/drawer/Drawer";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../shared/components/tabs/Tabs";
import { DIGITAL_PROFILE_TYPE, SALE_ACTION_STATUS } from "../../../../shared/constants/models";
import { UseCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { formatters } from "../../../../shared/utils";
import {
  generateFormattedMessages,
  getCorrectMatchStatus,
  getDoNotMessageStatus,
  getPersonalConnectionStatus,
  getSaleStatus,
} from "../utils";
import {
  ChatContainer,
  MainContainer,
  MessageInput,
  MessageList,
} from "@chatscope/chat-ui-kit-react";
import { ConversationEditFields, ConversationMessage } from "../types";
import { UseMutateAsyncFunction } from "react-query";
import { TagCampaignLeadProfileBody } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useEffect, useState } from "react";
import EditSaleStatus from "./EditSaleStatus";
import EditCorrectMatch from "./EditCorrectMatch";
import EditPersonalConnection from "./EditPersonalConnection";
import EditDoNotMessage from "./EditDoNotMessage";
import { OVERLEAD_TEAM_ID, OVERLEAD_USER_ID } from "../../../../shared/constants";
import { orderBy } from "lodash";

interface DataTableDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  sendMessage: (message: string) => void;
  markAsRead: (threadId: number) => void;
  markAsUnread: (threadId: number) => void;
  data: UseCampaignLeadProfileById["campaignLeadProfile"];
  tagCampaignLeadProfileMutation: UseMutateAsyncFunction<
    any,
    any,
    TagCampaignLeadProfileBody,
    void
  >;
  tagCampaignLeadProfileMutationLoading: boolean;
}

export function DataTableDrawer({
  open,
  onOpenChange,
  sendMessage,
  markAsRead,
  markAsUnread,
  data,
  tagCampaignLeadProfileMutation,
  tagCampaignLeadProfileMutationLoading,
}: DataTableDrawerProps) {
  useEffect(() => {
    if (open && data?.conversation_thread?.id) {
      markAsRead(data?.conversation_thread?.id);
    }
  }, [open, data?.conversation_thread?.id]);

  const handleMarkAsUnread = () => {
    if (data?.conversation_thread?.id) {
      markAsUnread(data?.conversation_thread?.id);
    }
  };

  const [isEditingField, setIsEditingField] = useState<ConversationEditFields>();

  const statusId = data?.lastStatus?.sale_action_status.id;
  const statusName = data?.lastStatus?.sale_action_status.name;

  const messages: ConversationMessage[] =
    data?.conversation_thread && data.conversation_thread?.conversation_messages.length > 0
      ? orderBy(
          data.conversation_thread?.conversation_messages.map(message => ({
            id: message.id,
            content: message.content,
            admin_seen_at: message.admin_seen_at ? new Date(message.admin_seen_at) : null,
            is_outbound: message.is_outbound,
            external_created_at: new Date(message.external_created_at),
            conversation_message_status: message.conversation_message_status,
          })),
          ["external_created_at"],
          ["asc"]
        )
      : [];

  const formattedMessages = generateFormattedMessages(messages);

  const inboundMessages = messages.filter(message => !message.is_outbound);
  const hasUnreadMessages =
    inboundMessages.length > 0 && inboundMessages.every(message => !!message.admin_seen_at);

  let conversationUrl = null;

  const externalOutreachAgentId = data?.campaign_lead?.campaign?.outreach_agent?.external_id;
  const externalLinkedinUserId = data?.external_linkedin_user_id;
  if (externalOutreachAgentId && externalLinkedinUserId) {
    conversationUrl = `https://app.overlead.io/users/${OVERLEAD_USER_ID}/teams/${OVERLEAD_TEAM_ID}/accounts/${externalOutreachAgentId}/chat/all?linkedinUserId=${externalLinkedinUserId}`;
  }

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      {data ? (
        <DrawerContent className="overflow-x-hidden  dark:bg-gray-925">
          <DrawerHeader className="-px-6 w-full">
            <DrawerTitle className="flex w-full items-center justify-between">
              <span>{data.digital_profile?.name}</span>
              <span>{formatters.currency({ number: data.conversation_value })}</span>
            </DrawerTitle>
            <div className="mt-1 flex items-center justify-between">
              <span className="text-left text-sm text-gray-500 dark:text-gray-500">
                {getCorrectMatchStatus(data.is_incorrect_match)}
              </span>
              {getSaleStatus(statusId, statusName)}
            </div>
          </DrawerHeader>
          <DrawerBody className="-mx-6 overflow-y-scroll">
            {/* <div className="w-3/5  ">
                <Card className="bg-white p-0 pt-5 border-r-1 border-t-1 border-b-1 rounded-tl-none rounded-bl-none	 rounded-tr-lg rounded-br-lg shadow-[4px_0_6px_-1px_rgba(0,0,0,0.1)] ">
                  <MainContainer className="border-0 bg-red-500" responsive>
                    <ChatContainer className="border-0 w-full bg-red-500">
                      <MessageList>
                        <MessageList>{formattedMessages}</MessageList>
                      </MessageList>
                    </ChatContainer>
                  </MainContainer>
                </Card>
                <div className="px-6 py-5">
                  <MessageInput
                    attachButton={false}
                    placeholder="Type message here"
                    // onSend={(innerHtml: string, textContent: string) =>
                    //   sendMessage(textContent)
                    // }
                  />
                </div>
              </div> */}

            <Tabs defaultValue="conversation">
              <TabsList className="px-6">
                <TabsTrigger value="conversation" className="px-4">
                  Conversation
                </TabsTrigger>
                <TabsTrigger value="tags" className="px-4">
                  Tags
                </TabsTrigger>
                <TabsTrigger value="campaign" className="px-4">
                  Campaign
                </TabsTrigger>
                <TabsTrigger value="address" className="px-4">
                  Address
                </TabsTrigger>
                {/* <TabsTrigger value="owner" className="px-4">
                  Owner
                </TabsTrigger> */}
                <TabsTrigger value="profile" className="px-4">
                  Profile
                </TabsTrigger>
              </TabsList>
              <TabsContent value="conversation" className="space-y-6 mb-4 p-6">
                <Card className=" ">
                  <MainContainer className="border-0 bg-red-500" responsive>
                    <ChatContainer className="border-0 w-full bg-red-500">
                      <MessageList>
                        <MessageList>{formattedMessages}</MessageList>
                      </MessageList>
                      <MessageInput
                        attachButton={false}
                        placeholder="Type message here"
                        onSend={(_, textContent: string) => sendMessage(textContent)}
                      />
                    </ChatContainer>
                  </MainContainer>
                </Card>
              </TabsContent>
              <TabsContent value="campaign" className="space-y-6 px-6">
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Type
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.campaign.campaign_type.name}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Workspace
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.campaign?.workspace?.name ?? "Not Allocated"}
                  </p>
                </div>
              </TabsContent>
              <TabsContent value="address" className="space-y-6 px-6">
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Listing URL
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.public_url ?? "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Matched AddressBase Address
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.address.full_address}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Scraped / Derived Address
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.stated_address ?? "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Agent
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.agent ?? "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Listed At
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.campaign_lead?.listed_at
                      ? dayjs(data.campaign_lead?.listed_at).format("DD/MM/YYYY")
                      : "Not Available"}
                  </p>
                </div>
              </TabsContent>
              <TabsContent value="owner" className="space-y-6 px-6">
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    First Name
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.owner_address_profile?.suspected_owner_address.suspected_owner
                      .first_name ?? "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Last Name
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.owner_address_profile?.suspected_owner_address.suspected_owner
                      .last_name ?? "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Full Name
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.owner_address_profile?.suspected_owner_address.suspected_owner.name ??
                      "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Data Source
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.owner_address_profile?.suspected_owner_address.suspected_owner
                      .ownership_source.name ?? "Not Available"}
                  </p>
                </div>
                <div>
                  <Label htmlFor="file" className="font-medium">
                    Scraped Address
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.owner_address_profile?.suspected_owner_address.suspected_owner
                      .stated_address ?? "Not Available"}
                  </p>
                </div>
              </TabsContent>
              <TabsContent value="profile" className="space-y-6 px-6">
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Name
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.digital_profile?.name ?? "Not Available"}
                  </p>
                </div>
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Profile Url
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.digital_profile?.value ?? "Not Available"}
                  </p>
                </div>
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Conversation Url
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {conversationUrl ? (
                      <a href={conversationUrl} target="_blank">
                        {conversationUrl}
                      </a>
                    ) : (
                      "Not Available"
                    )}
                  </p>
                </div>
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Personal Email
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.personal_email ?? "Not Available"}
                  </p>
                </div>
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Business Email
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.business_email ?? "Not Available"}
                  </p>
                </div>
                <div className="mt-6">
                  <Label htmlFor="file" className="font-medium">
                    Phone Number
                  </Label>
                  <p className="mt-1 text-sm leading-6 text-gray-500">
                    {data.phone_number ?? "Not Available"}
                  </p>
                </div>

                {data.digital_profile?.digital_profile_type.id ===
                  DIGITAL_PROFILE_TYPE.LINKEDIN && (
                  <>
                    <div>
                      <Label htmlFor="file" className="font-medium">
                        linkedIn Location
                      </Label>
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {data.digital_profile.primary_additional_info?.location ?? "Not Available"}
                      </p>
                    </div>
                    <div>
                      <Label htmlFor="file" className="font-medium">
                        linkedIn Job
                      </Label>
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {data.digital_profile.primary_additional_info?.job ?? "Not Available"}
                      </p>
                    </div>
                    <div>
                      <Label htmlFor="file" className="font-medium">
                        linkedIn Summary
                      </Label>
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {data.digital_profile.primary_additional_info?.summary ?? "Not Available"}
                      </p>
                    </div>
                  </>
                )}
              </TabsContent>
              <TabsContent value="tags" className="space-y-6 px-6">
                <div className="mt-6 flex flex-col sm:flex-row items-center sm:justify-between">
                  <div className="w-full">
                    <Label htmlFor="file" className="font-medium">
                      Match Status
                    </Label>
                    {isEditingField && data.id && isEditingField === "incorrectMatch" ? (
                      <EditCorrectMatch
                        setIsEditingField={setIsEditingField}
                        tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
                        tagCampaignLeadProfileMutationLoading={
                          tagCampaignLeadProfileMutationLoading
                        }
                        campaignLeadProfileId={data.id}
                      />
                    ) : (
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {getCorrectMatchStatus(data.is_incorrect_match)}
                      </p>
                    )}
                  </div>
                  {!isEditingField && (
                    <div className="">
                      <Button
                        type="button"
                        onClick={() => {
                          setIsEditingField("incorrectMatch");
                        }}
                        variant="secondary"
                        className="max-w-[4.5rem]"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col sm:flex-row items-center sm:justify-between">
                  <div className="w-full">
                    <Label htmlFor="file" className="font-medium">
                      Personal Connection
                    </Label>
                    {isEditingField && data.id && isEditingField === "isPersonalConversation" ? (
                      <EditPersonalConnection
                        setIsEditingField={setIsEditingField}
                        tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
                        tagCampaignLeadProfileMutationLoading={
                          tagCampaignLeadProfileMutationLoading
                        }
                        campaignLeadProfileId={data.id}
                      />
                    ) : (
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {getPersonalConnectionStatus(data.is_personal_connection)}
                      </p>
                    )}
                  </div>
                  {!isEditingField && (
                    <div className="">
                      <Button
                        type="button"
                        onClick={() => {
                          setIsEditingField("isPersonalConversation");
                        }}
                        variant="secondary"
                        className="max-w-[4.5rem]"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col w-full sm:flex-row items-center sm:justify-between">
                  <div className="w-full">
                    <Label htmlFor="file" className="font-medium">
                      Sale Status
                    </Label>
                    {isEditingField && data.id && isEditingField === "saleActionStatus" ? (
                      <EditSaleStatus
                        setIsEditingField={setIsEditingField}
                        tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
                        tagCampaignLeadProfileMutationLoading={
                          tagCampaignLeadProfileMutationLoading
                        }
                        campaignLeadProfileId={data.id}
                      />
                    ) : (
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {getSaleStatus(statusId, statusName)}
                      </p>
                    )}
                  </div>
                  {!isEditingField && (
                    <div className="">
                      <Button
                        type="button"
                        onClick={() => {
                          setIsEditingField("saleActionStatus");
                        }}
                        variant="secondary"
                        className="max-w-[4.5rem]"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col sm:flex-row items-center sm:justify-between">
                  <div className="w-full">
                    <Label htmlFor="file" className="font-medium">
                      Do Not Message
                    </Label>
                    {isEditingField && data.id && isEditingField === "doNotMessage" ? (
                      <EditDoNotMessage
                        setIsEditingField={setIsEditingField}
                        tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
                        tagCampaignLeadProfileMutationLoading={
                          tagCampaignLeadProfileMutationLoading
                        }
                        campaignLeadProfileId={data.id}
                      />
                    ) : (
                      <p className="mt-1 text-sm leading-6 text-gray-500">
                        {getDoNotMessageStatus(data.is_do_not_message)}
                      </p>
                    )}
                  </div>
                  {!isEditingField && (
                    <div className="">
                      <Button
                        type="button"
                        onClick={() => {
                          setIsEditingField("doNotMessage");
                        }}
                        variant="secondary"
                        className="max-w-[4.5rem]"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>
              </TabsContent>
            </Tabs>
          </DrawerBody>
          <DrawerFooter className="-mx-6 -mb-2 gap-2 bg-white px-6 dark:bg-gray-925">
            {hasUnreadMessages ? (
              <DrawerClose>
                <Button variant="secondary" className="w-full" onClick={handleMarkAsUnread}>
                  Mark as Unread
                </Button>
              </DrawerClose>
            ) : null}
          </DrawerFooter>
        </DrawerContent>
      ) : null}
    </Drawer>
  );
}
/* 
<Tabs defaultValue="details">
<TabsList className="px-6">
  <TabsTrigger value="details" className="px-4">
    Details
  </TabsTrigger>
  <TabsTrigger value="accounting" className="px-4">
    Accounting
  </TabsTrigger>
</TabsList>
<TabsContent value="details" className="space-y-6 px-6">
  <div className="mt-6">
    <Label htmlFor="file" className="font-medium">
      Upload receipt
    </Label>
    <div className="relative mt-2 flex h-36 items-center justify-center rounded-lg border border-dashed border-gray-300 dark:border-gray-700">
      <div>
       
        <div className="mt-2">
          <label
            htmlFor="file-upload"
            className="cursor-pointer rounded-md text-sm text-gray-700 dark:text-gray-300"
          >
        
            <span aria-hidden="true" className="absolute inset-0" />
            Click
            <input
              // {...getInputProps()}
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
            />
          </label>
          <span className="pl-1 text-sm text-gray-700 dark:text-gray-300">
            to browse or drag receipt here
          </span>
          <p className="text-center text-xs text-gray-500 dark:text-gray-500">
            PDF, JPG, PNG, XML
          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Label className="font-medium" htmlFor="category">
      Accounting Categorization
    </Label>
    <Select>
      <SelectTrigger id="category" className="mt-2">
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
         {categories.map((category, index) => (
          <SelectItem key={index} value={category}>
            {category}
          </SelectItem>
        ))} 
      </SelectContent>
    </Select>
  </div>
  <div>
    <Label className="font-medium" htmlFor="memo">
      Memo
    </Label>
    <Input
      id="memo"
      name="memo"
      type="text"
      placeholder="Describe the business purpose for this expense"
      className="mt-2"
    />
  </div>
</TabsContent>
<TabsContent value="accounting" className="space-y-6 px-6">
  <h3 className="mt-6 text-sm font-medium text-gray-900 dark:text-gray-50">
    Audit trail
  </h3>
  <DataTableDrawerFeed /> 
</TabsContent>
</Tabs>
*/
