import { gql } from "@apollo/client";

export const getCampaignExternalStatuses = gql`
  query getCampaignExternalStatuses {
    campaign_status {
      id
      name
    }
  }
`;
