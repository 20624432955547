import { useQuery } from "@apollo/client";
import { GetCampaignExternalStatusesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getCampaignExternalStatuses } from "../queries/getCampaignExternalStatuses";

export type UseCampaignExternalStatuses = ReturnType<typeof useCampaignExternalStatuses>;

export const useCampaignExternalStatuses = () => {
  const { data, loading, error, refetch } = useQuery<GetCampaignExternalStatusesQuery>(
    getCampaignExternalStatuses
  );

  const campaignExternalStatuses =
    data?.campaign_status && Array.isArray(data?.campaign_status)
      ? data?.campaign_status.map(campaignStatus => ({
          id: campaignStatus.id,
          name: campaignStatus.name,
        }))
      : [];

  return {
    campaignExternalStatuses,
    campaignExternalStatusesError: error,
    campaignExternalStatusesLoading: loading,
    fetchStatuses: refetch,
  };
};
