import { useParams } from "react-router-dom";
import { Button } from "../../../../shared/components/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { Tooltip } from "../../../../shared/components/tooltip/Tooltip";
import { RiAddLine, RiMore2Fill } from "@remixicon/react";
import { useWorkspaceById } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaceById";
import { useUsers } from "../../../../shared/domains/users/graphql/hooks/useUsers";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Label } from "../../../../shared/components/label/Label";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useEffect } from "react";
import React from "react";
import {
  UseOutreachAgentsByWorkspaceId,
  useOutreachAgentsByWorkspaceId,
} from "../../../../shared/domains/outreachAgents/graphql/hooks/useOutreachAgentsByWorkspaceId";
import { DataTable } from "../components/DataTable";
import { getColumns } from "../components/Columns";
import {
  AddOutreachAgentBody,
  useAddOutreachAgentAPI,
} from "../../../../shared/domains/outreachAgents/rest/addOutreachAgentAPI";
import {
  createOutreachAgentSchema,
  CreateOutreachAgentValues,
  updateOutreachAgentSchema,
  UpdateOutreachAgentValues,
} from "../validation";
import { useOutreachAgentTypes } from "../../../../shared/domains/static/graphql/hooks/useOutreachAgentTypes";
import { useOutreachPlatforms } from "../../../../shared/domains/static/graphql/hooks/useOutreachPlatforms";
import { useCampaignExternalConnectionProviders } from "../../../../shared/domains/static/graphql/hooks/useCampaignExternalConnectionProviders";
import { Input } from "../../../../shared/components/input/Input";
import { Switch } from "../../../../shared/components/switch/Switch";
import { DataTableDrawer } from "../components/DataTableDrawer";
import { ArrayElement } from "../../../../shared/utils";
import { Row } from "@tanstack/react-table";
import { useUpdateOutreachAgentAPI } from "../../../../shared/domains/outreachAgents/rest/updateOutreachAgentAPI";
import { useVerifyOutreachAgentAPI } from "../../../../shared/domains/outreachAgents/rest/verifyOutreachAgentAPI";

export default function SingleWorkspaceChannels() {
  let { workspaceId } = useParams<{ workspaceId: string }>();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [row, setRow] = React.useState<ArrayElement<
    UseOutreachAgentsByWorkspaceId["outreachAgents"]
  > | null>(null);

  const { outreachAgents, refetchOutreachAgents } = useOutreachAgentsByWorkspaceId({
    workspaceId: Number(workspaceId),
  });
  const { outreachAgentTypes } = useOutreachAgentTypes();
  const { outreachPlatforms } = useOutreachPlatforms();
  const { campaignExternalConnectionProviders } = useCampaignExternalConnectionProviders();

  const columns = getColumns({
    onEditClick: row => {
      setRow(row.original);
      setIsDrawerOpen(true);
    },
    onActivationClick: async (row, type) => {
      await updateOutreachAgentMutation({
        outreachAgentId: row.original.id,
        platformIdentifier: row.original.platform_identifier,
        outreachPlatformId: Number(row.original.outreach_platform.id),
        externalId: Number(row.original.external_id),
        externalCredentialId: row.original.external_credential_id ?? undefined,
        outreachAgentTypeId: Number(row.original.outreach_agent_type.id),
        providerId: Number(row.original.campaign_external_connection_provider.id),
        isActive: type === "activate" ? true : false,
        workspaceId: Number(workspaceId),
      });
    },
    onVerificationClick: async (row, type) => {
      await verifyOutreachAgentMutation({
        outreachAgentId: row.original.id,
      });
    },
  });

  const {
    mutateAsync: verifyOutreachAgentMutation,
    isSuccess: verifyOutreachAgentMutationSuccess,
  } = useVerifyOutreachAgentAPI();

  const {
    mutateAsync: addOutreachAgentMutation,
    isSuccess: addOutreachAgentMutationSuccess,
    isLoading: addOutreachAgentMutationLoading,
  } = useAddOutreachAgentAPI();

  const {
    mutateAsync: updateOutreachAgentMutation,
    isSuccess: updateOutreachAgentMutationSuccess,
    isLoading: updateOutreachAgentMutationLoading,
  } = useUpdateOutreachAgentAPI();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateOutreachAgentValues>({
    resolver: yupResolver(createOutreachAgentSchema),
    defaultValues: {
      isActive: true,
    },
  });

  const {
    register: updateOutreachAgentRegister,
    handleSubmit: updateOutreachAgentHandleSubmit,
    reset: updateOutreachAgentReset,
    setValue: updateOutreachAgentSetValue,
    control: updateOutreachAgentControl,
    formState: { errors: updateOutreachAgentErrors },
  } = useForm<UpdateOutreachAgentValues>({
    resolver: yupResolver(updateOutreachAgentSchema),
  });

  useEffect(() => {
    if (addOutreachAgentMutationSuccess) {
      reset(undefined);
      refetchOutreachAgents();
      setIsDialogOpen(false);
    }
  }, [addOutreachAgentMutationSuccess]);

  useEffect(() => {
    if (verifyOutreachAgentMutationSuccess) {
      reset(undefined);
      refetchOutreachAgents();
    }
  }, [verifyOutreachAgentMutationSuccess]);

  useEffect(() => {
    if (updateOutreachAgentMutationSuccess) {
      updateOutreachAgentReset(undefined);
      refetchOutreachAgents();
      setRow(null);
      setIsDrawerOpen(false);
    }
  }, [updateOutreachAgentMutationSuccess]);

  const addOutreachAgentOnSubmit = async (data: CreateOutreachAgentValues) => {
    await addOutreachAgentMutation({
      platformIdentifier: data.identifier,
      outreachPlatformId: Number(data.platformId),
      externalId: Number(data.externalId),
      externalCredentialId: data.externalCredentialId,
      outreachAgentTypeId: Number(data.typeId),
      providerId: Number(data.providerId),
      isActive: data.isActive,
      workspaceId: Number(workspaceId),
    });
  };

  const updateOutreachAgentOnSubmit = async (data: UpdateOutreachAgentValues) => {
    await updateOutreachAgentMutation({
      outreachAgentId: data.outreachAgentId,
      platformIdentifier: data.identifier,
      outreachPlatformId: Number(data.platformId),
      externalId: Number(data.externalId),
      externalCredentialId: data.externalCredentialId,
      outreachAgentTypeId: Number(data.typeId),
      providerId: Number(data.providerId),
      isActive: data.isActive,
      workspaceId: Number(workspaceId),
    });
  };

  return (
    <>
      <section aria-labelledby="existing-users">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3
              id="existing-users"
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
            >
              Channels
            </h3>
            <p className="text-sm leading-6 text-gray-500">
              You can add and manage channels for this workspace.
            </p>
          </div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button className="mt-4 w-full gap-2 sm:mt-0 sm:w-fit">
                <RiAddLine className="-ml-1 size-4 shrink-0" aria-hidden="true" />
                Add Channels
              </Button>
            </DialogTrigger>

            <DialogContent className="sm:max-w-lg max-h-screen sm:max-h-[90vh]">
              <DialogHeader>
                <DialogTitle>Add a Channel to the workspace</DialogTitle>
                <DialogDescription className="mt-1 text-sm leading-6">
                  Channels are external platforms where agents can send messages to leads.
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit(addOutreachAgentOnSubmit)} className="mt-4  space-y-4">
                <div>
                  <Label htmlFor="role-new-user" className="font-medium">
                    Platform
                  </Label>
                  <Controller
                    control={control}
                    name="platformId"
                    render={({ field }) => {
                      return (
                        <Select onValueChange={field.onChange} {...field}>
                          <SelectTrigger id="platform" name="platform" className="mt-2">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent align="end">
                            {outreachPlatforms.map(platform => (
                              <SelectItem key={platform.id} value={platform.id?.toString()}>
                                {platform.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.platformId && <ErrorMessage>{errors.platformId.message}</ErrorMessage>}
                </div>

                <div>
                  <Label htmlFor="role-new-user" className="font-medium">
                    Provider
                  </Label>
                  <Controller
                    control={control}
                    name="providerId"
                    render={({ field }) => {
                      return (
                        <Select onValueChange={field.onChange} {...field}>
                          <SelectTrigger id="provider" name="provider" className="mt-2">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent align="end">
                            {campaignExternalConnectionProviders.map(platform => (
                              <SelectItem key={platform.id} value={platform.id?.toString()}>
                                {platform.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.providerId && <ErrorMessage>{errors.providerId.message}</ErrorMessage>}
                </div>

                <div>
                  <Label htmlFor="role-new-user" className="font-medium">
                    Type
                  </Label>
                  <Controller
                    control={control}
                    name="typeId"
                    render={({ field }) => {
                      return (
                        <Select onValueChange={field.onChange} {...field}>
                          <SelectTrigger id="type" name="type" className="mt-2">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent align="end">
                            {outreachAgentTypes.map(platform => (
                              <SelectItem key={platform.id} value={platform.id?.toString()}>
                                {platform.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.typeId && <ErrorMessage>{errors.typeId.message}</ErrorMessage>}
                </div>

                <div>
                  <Label htmlFor="name" className="font-medium">
                    Identifier
                  </Label>
                  <p className="text-xs -mt-2 leading-6 text-gray-500">
                    Please input the platform username (e.g. Email for Linkedin)
                  </p>
                  <Input {...register("identifier")} type="text" className="mt-2" />
                  {errors.identifier && <ErrorMessage>{errors.identifier.message}</ErrorMessage>}
                </div>

                <div>
                  <Label htmlFor="name" className="font-medium">
                    External Id
                  </Label>
                  <Input {...register("externalId")} type="number" className="mt-2" />
                  {errors.externalId && <ErrorMessage>{errors.externalId.message}</ErrorMessage>}
                </div>

                <div>
                  <Label htmlFor="name" className="font-medium">
                    External Credential Id
                  </Label>
                  <p className="text-xs leading-2 text-gray-500">
                    The id of the 1Password credential for this channel (no passwords are stored in
                    the system). Keep empty if no 1Password entry is available.
                  </p>
                  <Input {...register("externalCredentialId")} type="text" className="mt-2" />
                  {errors.externalCredentialId && (
                    <ErrorMessage>{errors.externalCredentialId.message}</ErrorMessage>
                  )}
                </div>

                <div>
                  <div className="flex mt-5 items-center gap-2">
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch id="isActive" checked={value} onCheckedChange={onChange} />
                      )}
                    />

                    <Label className="font-medium" htmlFor="isActive">
                      Activate Channel
                    </Label>
                  </div>

                  {errors.isActive && <ErrorMessage>{errors.isActive.message}</ErrorMessage>}
                </div>

                <DialogFooter className="mt-6">
                  <DialogClose asChild>
                    <Button
                      type="button"
                      className="mt-2 w-full sm:mt-0 sm:w-fit"
                      variant="secondary"
                    >
                      Go back
                    </Button>
                  </DialogClose>
                  <Button
                    type="submit"
                    disabled={addOutreachAgentMutationLoading}
                    className="w-full sm:w-fit"
                  >
                    Add Channel
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <div className="mt-4 sm:mt-6 lg:mt-10">
          <DataTable
            data={outreachAgents}
            columns={columns}
            // onRowClick={row => {
            //   history.push(`/workspaces/${row.original.id}/general`);
            // }}
          />
          {row && (
            <DataTableDrawer
              outreachAgentTypes={outreachAgentTypes}
              outreachPlatforms={outreachPlatforms}
              campaignExternalConnectionProviders={campaignExternalConnectionProviders}
              updateOutreachAgentMutationLoading={updateOutreachAgentMutationLoading}
              updateOutreachAgentOnSubmit={updateOutreachAgentOnSubmit}
              updateOutreachAgentErrors={updateOutreachAgentErrors}
              updateOutreachAgentControl={updateOutreachAgentControl}
              updateOutreachAgentRegister={updateOutreachAgentRegister}
              updateOutreachAgentHandleSubmit={updateOutreachAgentHandleSubmit}
              updateOutreachAgentSetValue={updateOutreachAgentSetValue}
              open={isDrawerOpen}
              onOpenChange={setIsDrawerOpen}
              data={row}
            />
          )}
        </div>
      </section>
    </>
  );
}
