import { RadioGroup } from "@headlessui/react";

import {
  UseFormSetValue,
  UseFormTrigger,
  UseFormRegister,
  FieldErrorsImpl,
  DeepRequired,
  UseFormWatch,
  UseFormResetField,
  UseFormReset,
  Controller,
  Control,
  UseFormClearErrors,
} from "react-hook-form";
import { useEffect, useState } from "react";
import { UseCampaignTypes } from "../../../../../shared/domains/static/graphql/hooks/useCampaignTypes";
import { CommisionCampaignValues } from "../validation";
import { visibleCampaignTypes } from "../data";
import { extractAvailableCampaignTypes } from "../../utils";
import { classNames } from "../../../../../shared/utils";
import ErrorMessage from "../../../../../shared/components/errorMessage/ErrorMessage";
import { InformationCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { UseWorkspaces } from "../../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { useLazyOutreachAgentsByWorkspaceId } from "../../../../../shared/domains/outreachAgents/graphql/hooks/useOutreachAgentsByWorkspaceId";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../shared/components/select/Select";
import ReactSelect from "react-select";
import { reactSelectStyling } from "../../../../../shared/styles";
import { Badge } from "../../../../../shared/components/badge/Badge";
import { WORKSPACE_STATUS } from "../../../../../shared/constants/models";

interface IntentSignalSelectionProps {
  campaignTypes: UseCampaignTypes["campaignTypes"];
  register: UseFormRegister<CommisionCampaignValues>;
  setValue: UseFormSetValue<CommisionCampaignValues>;
  errors: FieldErrorsImpl<DeepRequired<CommisionCampaignValues>>;
  campaignTypeId: number;
  nextStep: () => Promise<void>;
  trigger: UseFormTrigger<CommisionCampaignValues>;
  currentStep: number;
  resetField: UseFormResetField<CommisionCampaignValues>;
  reset: UseFormReset<CommisionCampaignValues>;
  watch: UseFormWatch<CommisionCampaignValues>;
  workspaces: UseWorkspaces["workspaces"];
  control: Control<CommisionCampaignValues, any, CommisionCampaignValues>;
  clearErrors: UseFormClearErrors<CommisionCampaignValues>;
}

const IntentSignalSelection = ({
  campaignTypes,
  campaignTypeId,
  resetField,
  setValue,
  errors,
  workspaces,
  reset,
  register,
  trigger,
  nextStep,
  watch,
  clearErrors,
  control,
  currentStep,
}: IntentSignalSelectionProps) => {
  const availableCampaignTypes = extractAvailableCampaignTypes(campaignTypes);
  const { fetchOutreachAgents, verifiedOutreachAgents } = useLazyOutreachAgentsByWorkspaceId();
  const workspace = watch("workspace");

  const validateAndNext = async () => {
    const result = await trigger(["campaignTypeId", "workspace", "outreachAgents"]);
    if (result) {
      nextStep();
    }
  };

  const onWorkspaceSelect = async (value: number) => {
    resetField("outreachAgents", { defaultValue: [] });
    await fetchOutreachAgents({
      variables: { id: Number(value) },
    });
  };

  return (
    <>
      <div className="bg-gray-50 pb-20 min-h-screen h-full flex-wrap flex flex-col items-center justify-center focus:outline-none">
        <div className="relative w-full mx-auto px-8 pt-10 pb-8 md:px-10 xl:px-12">
          <div className=" max-w-3xl mx-auto space-y-8">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {currentStep}. What <span className="text-orange-500">Intent Signal</span> do you
                wish to target?
              </h2>
              <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-orange-700">
                      You will still be able to configure the campaign after selecting the intent
                      signal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-3xl mx-auto">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white mt-5 px-4 py-5 sm:p-6">
                  <div className="w-full">
                    <div>
                      <div className="text-lg my-4 font-medium text-gray-900">
                        Select your intent signal
                      </div>
                      <RadioGroup
                        onChange={(value: number) => {
                          setValue("campaignTypeId", value);
                          clearErrors("campaignTypeId");
                        }}
                        value={campaignTypeId}
                      >
                        <div className="mt-4 grid grid-cols-1 gap-y-6  md:grid-cols-3 md:gap-x-4 ">
                          {availableCampaignTypes.map(type => (
                            <RadioGroup.Option
                              key={type.id}
                              value={type.id}
                              className={({ checked, active }) =>
                                classNames(
                                  checked ? "border-transparent" : "border-gray-300",
                                  active ? "border-orange-500 ring-2 ring-orange-500" : "",
                                  "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                                )
                              }
                            >
                              {({ checked, active }) => (
                                <>
                                  <span className="flex flex-1">
                                    <span className="flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className="block text-sm font-medium text-gray-900"
                                      >
                                        {type.name}{" "}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className="block text-xs font-medium text-gray-400"
                                      >
                                        {type.description}
                                      </RadioGroup.Description>
                                    </span>
                                  </span>
                                  <CheckCircleIcon
                                    className={classNames(
                                      !checked ? "invisible" : "",
                                      "h-5 w-5 text-orange-600"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={classNames(
                                      active ? "border" : "border-2",
                                      checked ? "border-orange-500" : "border-transparent",
                                      "pointer-events-none absolute -inset-px rounded-lg"
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>

                      {errors.campaignTypeId && (
                        <ErrorMessage>{errors.campaignTypeId.message}</ErrorMessage>
                      )}
                    </div>
                    <div>
                      <div className="text-lg my-4 font-medium text-gray-900">Select Workspace</div>
                      <div>
                        <Controller
                          name="workspace"
                          control={control}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              styles={reactSelectStyling}
                              onChange={value => {
                                if (value) {
                                  field.onChange(value);
                                  clearErrors("workspace");
                                  onWorkspaceSelect(value.value);
                                }
                              }}
                              maxMenuHeight={220}
                              options={workspaces.map(workspace => {
                                return {
                                  label: workspace.name,
                                  value: workspace.id,
                                };
                              })}
                              isSearchable
                              placeholder="Select Workspace"
                            />
                          )}
                        />

                        {errors.workspace && <ErrorMessage>Please select a Workspace</ErrorMessage>}
                      </div>
                    </div>
                    {workspace && workspace?.value && (
                      <div>
                        <div className="text-lg my-4 font-medium text-gray-900">Select Channel</div>
                        <div>
                          <Controller
                            name="outreachAgents"
                            control={control}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                styles={reactSelectStyling}
                                maxMenuHeight={220}
                                options={verifiedOutreachAgents.map(outreachAgent => {
                                  return {
                                    label: `${outreachAgent.platform_identifier} - ${outreachAgent.outreach_platform.name}`,
                                    value: outreachAgent.id,
                                  };
                                })}
                                isSearchable
                                isMulti
                                placeholder="Select Channel"
                              />
                            )}
                          />
                          {errors.outreachAgents && (
                            <ErrorMessage>{errors.outreachAgents.message}</ErrorMessage>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 mt-4">
              <div className="flex space-x-6">
                <button
                  type="button"
                  onClick={() => validateAndNext()}
                  className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntentSignalSelection;
