import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

type Payload = {
  campaignLeadProfileExternalId: number;
  message: string;
};

export const sendMessageAPI = async ({ campaignLeadProfileExternalId, message }: Payload) => {
  const payload = {
    campaignLeadProfileExternalId,
    message,
  };

  const response = await meetmoreAPI.post(`/conversation-threads`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useSendMessageAPI = () => {
  let loadingToastId: string;

  return useMutation(sendMessageAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Sending message...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Message sent!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
