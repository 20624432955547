import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";
import { Badge } from "../../../../shared/components/badge/Badge";
import { ArrayElement } from "../../../../shared/utils";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";
import { UseGetCampaigns } from "../../../../shared/domains/campaigns/graphql/hooks/useGetCampaigns";
import { Button } from "../../../../shared/components/button/Button";

import { RiMore2Fill } from "@remixicon/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";

const columnHelper = createColumnHelper<ArrayElement<UseGetCampaigns["campaigns"]>>();

export const getColumns = ({
  onEditClick,
  onManageClick,
  onSyncClick,
}: {
  onEditClick: (row: Row<ArrayElement<UseGetCampaigns["campaigns"]>>) => void;
  onManageClick: (row: Row<ArrayElement<UseGetCampaigns["campaigns"]>>) => void;
  onSyncClick: (row: Row<ArrayElement<UseGetCampaigns["campaigns"]>>) => void;
}) =>
  [
    columnHelper.accessor("id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Id" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Id",
      },
      id: "Id",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.id}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("name", {
      header: ({ column }) => <TableColumnHeader column={column} title="Name" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Name",
      },
      id: "name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row  items-center">
            <span className="font-normal"> {row.original.name}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("workspace", {
      header: ({ column }) => <TableColumnHeader column={column} title="Workspace" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Workspace",
      },
      id: "workspace.name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <span className="font-normal"> {row.original.workspace}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("outreachAgentPlatformIdentifier", {
      header: ({ column }) => <TableColumnHeader column={column} title="Channel" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Channel",
      },
      id: "outreach_agent.platform_identifier",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <span className="font-normal"> {row.original.outreachAgentPlatformIdentifier}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("campaignType", {
      header: ({ column }) => <TableColumnHeader column={column} title="Type" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Type",
      },
      id: "campaign_type.name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <span className="font-normal"> {row.original.campaignType}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("syncRatio", {
      header: ({ column }) => <TableColumnHeader column={column} title="Sync Ratio" />,
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Sync Status",
      },
      id: "campaign_sync_ratio",
      cell: ({ row }) => {
        const percentageValue = Math.round(row.original.syncRatio * 100);
        return (
          <div className="flex flex-row items-center">
            <Badge variant={percentageValue > 80 ? "success" : "neutral"}>{percentageValue}%</Badge>
          </div>
        );
      },
    }),
    columnHelper.accessor("campaignInternalStatus", {
      header: ({ column }) => <TableColumnHeader column={column} title="Internal Status" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Internal Status",
      },
      id: "campaign_internal_status.name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <Badge
              variant={row.original.campaignInternalStatus === "active" ? "success" : "neutral"}
            >
              {row.original.campaignInternalStatus}
            </Badge>
          </div>
        );
      },
    }),
    columnHelper.accessor("campaignExternalStatus", {
      header: ({ column }) => <TableColumnHeader column={column} title="External Status" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "External Status",
      },
      id: "campaign_status.name",
      cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center">
            <Badge
              variant={row.original.campaignInternalStatus === "Completed" ? "success" : "neutral"}
            >
              {row.original.campaignExternalStatus}
            </Badge>
          </div>
        );
      },
    }),
    columnHelper.display({
      id: "edit",
      header: "Edit",
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Edit",
      },
      cell: ({ row }) => {
        return (
          <div className="flex items-center gap-2 z-50">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant="ghost"
                  className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                >
                  <RiMore2Fill
                    className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                    aria-hidden="true"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-36 -mt-2 z-500">
                <DropdownMenuItem onClick={() => onEditClick(row)}>Edit</DropdownMenuItem>
                <DropdownMenuItem onClick={() => onManageClick(row)}>Manage</DropdownMenuItem>
                <DropdownMenuItem onClick={() => onSyncClick(row)}>Sync</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    }),
  ] as ColumnDef<ArrayElement<UseGetCampaigns["campaigns"]>>[];
