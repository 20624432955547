import { getPostcodeDistricts } from "../queries/getPostcodeDistricts";
import { GetPostcodeDistrictsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UsePostcodeDistricts = ReturnType<typeof usePostcodeDistricts>;

export const usePostcodeDistricts = () => {
  const { data, loading, error } = useQuery<GetPostcodeDistrictsQuery>(getPostcodeDistricts);
  return {
    postcodeDistricts: data && Array.isArray(data.postcode_district) ? data.postcode_district : [],
    postcodeDistrictsError: error,
    postcodeDistrictsLoading: loading,
  };
};
