import { useQuery } from "@apollo/client";
import { GetCampaignInternalStatusesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getCampaignInternalStatuses } from "../queries/getCampaignInternalStatuses";

export type UseCampaignInternalStatuses = ReturnType<typeof useCampaignInternalStatuses>;

export const useCampaignInternalStatuses = () => {
  const { data, loading, error, refetch } = useQuery<GetCampaignInternalStatusesQuery>(
    getCampaignInternalStatuses
  );

  const campaignInternalStatuses =
    data?.campaign_internal_status && Array.isArray(data?.campaign_internal_status)
      ? data?.campaign_internal_status.map(campaignStatus => ({
          id: campaignStatus.id,
          name: campaignStatus.name,
        }))
      : [];

  return {
    campaignInternalStatuses,
    campaignInternalStatusesError: error,
    campaignInternalStatusesLoading: loading,
    fetchInternalStatuses: refetch,
  };
};
