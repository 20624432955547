import { gql } from "@apollo/client";

export const getCampaignExternalConnectionProviders = gql`
  query getCampaignExternalConnectionProviders {
    campaign_external_connection_provider {
      id
      name
    }
  }
`;
