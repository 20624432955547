import { ColumnSort } from "@tanstack/react-table";
import { Badge } from "../../../../shared/components/badge/Badge";
import { CAMPAIGN_INTERNAL_STATUS } from "../../../../shared/constants/models";

export const getCampaignStatus = (statusId: number | undefined, statusName: string | undefined) => {
  if (!statusId) {
    return <Badge variant={"neutral"}>No Campaign Status</Badge>;
  }

  switch (statusId) {
    case CAMPAIGN_INTERNAL_STATUS.HISTORICAL:
      return <Badge variant={"neutral"}>{statusName}</Badge>;
    case CAMPAIGN_INTERNAL_STATUS.NEW:
    case CAMPAIGN_INTERNAL_STATUS.ADDRESSES_REQUESTED:
    case CAMPAIGN_INTERNAL_STATUS.ADDRESSES_OBTAINED:
    case CAMPAIGN_INTERNAL_STATUS.ADDRESSES_CLEANED:
    case CAMPAIGN_INTERNAL_STATUS.OWNERS_REQUESTED:
    case CAMPAIGN_INTERNAL_STATUS.OWNERS_OBTAINED:
    case CAMPAIGN_INTERNAL_STATUS.OWNERS_CLEANED:
    case CAMPAIGN_INTERNAL_STATUS.PROFILES_REQUESTED:
    case CAMPAIGN_INTERNAL_STATUS.PROFILES_OBTAINED:
    case CAMPAIGN_INTERNAL_STATUS.PROFILES_CLEANED:
    case CAMPAIGN_INTERNAL_STATUS.OWNERS_MATCHED:
    case CAMPAIGN_INTERNAL_STATUS.PROFILES_MATCHED:
    case CAMPAIGN_INTERNAL_STATUS.PROFILES_SELECTED:
      return <Badge variant={"warning"}>{statusName}</Badge>;
    default:
      return <Badge variant={"neutral"}>{statusName}</Badge>;
  }
};

export const translateSortingQuery = ({ sorting }: { sorting: ColumnSort[] }) => {
  if (sorting.length === 0) {
    return [
      {
        id: "desc",
      },
    ];
  }

  return sorting.map(sort => {
    const fields = sort.id.split(".");

    const sortOrder = sort.desc ? "desc_nulls_last" : "asc_nulls_first";

    if (fields.length > 1) {
      let result = {};
      let current: any = result;
      for (let i = 0; i < fields.length - 1; i++) {
        current[fields[i]] = {};
        current = current[fields[i]];
      }
      current[fields[fields.length - 1]] = sortOrder;
      return result;
    }

    return {
      [sort.id]: sortOrder,
    };
  });
};
