import * as yup from "yup";

export const getSuspectedOwnersFromLandRegistrySchema = yup.object({
  email: yup.string().email().defined().required(),
  propertyTitle: yup.string().defined().required(),
  uprn: yup.string().defined().required(),
});

export type GetSuspectedOwnersFromLandRegistrySchema = yup.InferType<
  typeof getSuspectedOwnersFromLandRegistrySchema
>;
