import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface AddOrRemoveTeamMembersBody {
  workspaceId: number;
  userIdsToAdd: number[];
  userIdsToRemove: number[];
}

export const addOrRemoveTeamMembersAPI = async ({
  workspaceId,
  userIdsToAdd,
  userIdsToRemove,
}: AddOrRemoveTeamMembersBody) => {
  const payload = {
    userIdsToAdd,
    userIdsToRemove,
  };

  const response = await meetmoreAPI.post(
    `/workspaces/${workspaceId}/add-or-remove-members`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const useAddOrRemoveTeamMembersAPI = () => {
  let loadingToastId: string;

  return useMutation(addOrRemoveTeamMembersAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Editing workspace members...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Workspace Members Edited!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
