import { getMessageSets } from "../queries/getMessageSets";
import { GetMessageSetsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseMessageSets = ReturnType<typeof useMessageSets>;

export const useMessageSets = () => {
  const { data, loading, error } = useQuery<GetMessageSetsQuery>(getMessageSets);
  return {
    messageSets: data && Array.isArray(data.message_set) ? data.message_set : [],
    messageSetsError: error,
    messageSetsLoading: loading,
  };
};
