import {
  adminLayoutRoutes,
  adminNonLayoutRoutes,
  publicRoutes,
  restrictedRoutes,
  singleWorkspaceRoutes,
} from "./shared/routing/routes";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./shared/infrastructure/firebase";
import ApolloWrapper from "./shared/infrastructure/graphql/meetmore/ApolloWrapper";
import { usePostHog } from "posthog-js/react";
import { QueryClientProvider } from "react-query";
import { reactQueryClient } from "./shared/infrastructure/APIs/core/ReactQueryInstance";
import RestrictedRoute from "./shared/routing/restrictedRoute";
import AdminRoute from "./shared/routing/adminRoute";
import RootLayout from "./shared/layouts";
import SingleWorkspaceLayout from "./modules/workspaces/shared/layouts/SingleWorkspaceLayout";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

function App() {
  return (
    <Router>
      <QueryClientProvider client={reactQueryClient}>
        <ApolloWrapper>
          <CoreApp />
          <Toaster toastOptions={{ duration: 10000 }} position={"top-right"} />
        </ApolloWrapper>
      </QueryClientProvider>
    </Router>
  );
}

const CoreApp = () => {
  return (
    <>
      <Routes>
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.main />} />
        ))}

        {restrictedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<RestrictedRoute element={<route.main />} />}
          />
        ))}

        {adminLayoutRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <AdminRoute
                element={
                  <RootLayout>
                    <route.main />
                  </RootLayout>
                }
              />
            }
          />
        ))}

        {adminNonLayoutRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={<AdminRoute element={<route.main />} />} />
        ))}

        {singleWorkspaceRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <AdminRoute
                element={
                  <SingleWorkspaceLayout>
                    <route.main />
                  </SingleWorkspaceLayout>
                }
              />
            }
          />
        ))}

        {/* <Route path="*" element={<Navigate to="/not-found" />} /> */}
      </Routes>
    </>
  );
};

export default App;
