import React, { useState } from "react";
import Turnstone, { TurnstoneStyles, TurnstoneProps } from "turnstone";

interface TypeaheadProps {
  cancel?: React.ReactNode;
  clear?: React.ReactNode;
  styles: TurnstoneStyles;
  listbox: TurnstoneProps["listbox"];
  maxItems?: number;
  placeholder?: string;
  noItemsMessage?: string;
  id?: string;
  name?: string;
  isAutofocus?: boolean;
  debounceTime?: number;
  isDisabled?: boolean;
  listBoxIsImmutable?: boolean;
  matchText?: boolean;
  cancelButton?: boolean;
  clearButton?: boolean;
  errorMessage?: string;
  setHasFocus?: React.Dispatch<React.SetStateAction<boolean>>;
  hasFocus?: boolean;
  onSelect: (item: any) => void;
}

export default function Typeahead({
  cancel,
  clear,
  styles,
  errorMessage = "Something went wrong",
  listbox,
  debounceTime = 250,
  maxItems = 10,
  onSelect,
  id,
  name,

  listBoxIsImmutable = true,
  isAutofocus,
  isDisabled,
  cancelButton,
  clearButton,
  matchText = true,
  hasFocus,
  setHasFocus,
  noItemsMessage = "We found no items that match your search",
  placeholder = "Search",
}: TypeaheadProps) {
  // Style the container so on mobile devices the search box and results
  // take up the whole screen

  //   const onBlur = () => setHasFocus(false);
  //   const onFocus = () => setHasFocus(true);

  return (
    <div>
      <Turnstone
        disabled={isDisabled}
        autoFocus={isAutofocus}
        cancelButton={cancelButton}
        clearButton={clearButton}
        debounceWait={debounceTime}
        errorMessage={errorMessage}
        id={id}
        name={name}
        listbox={listbox}
        listboxIsImmutable={listBoxIsImmutable}
        matchText={matchText}
        maxItems={maxItems}
        noItemsMessage={noItemsMessage}
        // onBlur={onBlur}
        // onFocus={onFocus}
        placeholder={placeholder}
        styles={styles}
        onSelect={item => onSelect(item)}
        Cancel={cancel}
        Clear={cancel}
      />
    </div>
  );
}
