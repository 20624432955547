import * as yup from "yup";

// TODO: inferType is not working
export type CreateOutreachAgentValues = {
  identifier: string;
  platformId: string;
  externalId: string;
  externalCredentialId?: string;
  typeId: string;
  providerId: string;
  isActive: boolean;
};

export const createOutreachAgentSchema = yup.object().shape({
  identifier: yup.string().required("Identifier is required"),
  platformId: yup.string().required("Platform is required"),
  externalId: yup.string().required("External Id is required"),
  externalCredentialId: yup.string().optional(),
  typeId: yup.string().required("Channel Type is required"),
  providerId: yup.string().required("Provider is required"),
  isActive: yup.boolean().required("Status is required"),
});

export type UpdateOutreachAgentValues = {
  outreachAgentId: number;
  identifier: string;
  platformId: string;
  externalId: string;
  externalCredentialId?: string;
  typeId: string;
  providerId: string;
  isActive: boolean;
};

export const updateOutreachAgentSchema = yup.object().shape({
  outreachAgentId: yup.number().required("Outreach Agent Id is required"),
  identifier: yup.string().required("Identifier is required"),
  platformId: yup.string().required("Platform is required"),
  externalId: yup.string().required("External Id is required"),
  externalCredentialId: yup.string().optional(),
  typeId: yup.string().required("Channel Type is required"),
  providerId: yup.string().required("Provider is required"),
  isActive: yup.boolean().required("Status is required"),
});
