import { CAMPAIGN_AUTOMATION_LEVEL, CAMPAIGN_TYPE } from "../../../../../shared/constants/models";

export const priceOptions = [
  { id: 1, value: 0, label: "£0" },
  { id: 2, value: 25000, label: "£25k" },
  { id: 3, value: 50000, label: "£50k" },
  { id: 4, value: 75000, label: "£75k" },
  { id: 5, value: 100000, label: "£100k" },
  { id: 6, value: 125000, label: "£125k" },
  { id: 7, value: 150000, label: "£150k" },
  { id: 8, value: 175000, label: "£175k" },
  { id: 9, value: 200000, label: "£200k" },
  { id: 10, value: 225000, label: "£225k" },
  { id: 11, value: 250000, label: "£250k" },
  { id: 12, value: 275000, label: "£275k" },
  { id: 13, value: 300000, label: "£300k" },
  { id: 14, value: 325000, label: "£325k" },
  { id: 15, value: 350000, label: "£350k" },
  { id: 16, value: 375000, label: "£375k" },
  { id: 17, value: 400000, label: "£400k" },
  { id: 18, value: 425000, label: "£425k" },
  { id: 19, value: 450000, label: "£450k" },
  { id: 20, value: 475000, label: "£475k" },
  { id: 21, value: 500000, label: "£500k" },
  { id: 22, value: 525000, label: "£525k" },
  { id: 23, value: 550000, label: "£550k" },
  { id: 24, value: 575000, label: "£575k" },
  { id: 25, value: 600000, label: "£600k" },
  { id: 26, value: 625000, label: "£625k" },
  { id: 27, value: 650000, label: "£650k" },
  { id: 28, value: 675000, label: "£675k" },
  { id: 29, value: 700000, label: "£700k" },
  { id: 30, value: 725000, label: "£725k" },
  { id: 31, value: 750000, label: "£750k" },
  { id: 32, value: 775000, label: "£775k" },
  { id: 33, value: 800000, label: "£800k" },
  { id: 34, value: 825000, label: "£825k" },
  { id: 35, value: 850000, label: "£850k" },
  { id: 36, value: 875000, label: "£875k" },
  { id: 37, value: 900000, label: "£900k" },
  { id: 38, value: 925000, label: "£925k" },
  { id: 39, value: 950000, label: "£950k" },
  { id: 40, value: 975000, label: "£975k" },
  { id: 41, value: 1000000, label: "£1m" },
  { id: 42, value: 1100000, label: "£1.1m" },
  { id: 43, value: 1250000, label: "£1.25m" },
  { id: 44, value: 1500000, label: "£1.5m" },
  { id: 45, value: 1750000, label: "£1.75m" },
  { id: 46, value: 2000000, label: "£2m" },
  { id: 47, value: 2500000, label: "£2.5m" },
  { id: 48, value: 3000000, label: "£3m" },
  { id: 49, value: 4000000, label: "£4m" },
  { id: 50, value: 5000000, label: "£5m" },
  { id: 51, value: 7500000, label: "£7.5m" },
  { id: 52, value: 10000000, label: "£10m" },
  { id: 53, value: 15000000, label: "£15m" },
  { id: 54, value: 20000000, label: "£20m" },
  { id: 55, value: 1000000000, label: "No Limit" },
];

export const maximumAddresses = [
  { id: 1, value: 10, label: "10" },
  { id: 2, value: 25, label: "25" },
  { id: 3, value: 50, label: "50" },
  { id: 4, value: 75, label: "75" },
  { id: 5, value: 100, label: "100" },
  { id: 6, value: 150, label: "150" },
  { id: 7, value: 200, label: "200" },
  { id: 8, value: 250, label: "250" },
  { id: 9, value: 300, label: "300" },
  { id: 10, value: 350, label: "350" },
  { id: 11, value: 400, label: "400" },
  { id: 12, value: 450, label: "450" },
  { id: 13, value: 500, label: "500" },
];

export const daysOnMarketOptions = [
  { id: 1, value: 0, label: "0" },
  { id: 2, value: 1, label: "1" },
  { id: 3, value: 3, label: "3" },
  { id: 4, value: 7, label: "7, 1 week" },
  { id: 5, value: 14, label: "14, 2 weeks" },
  { id: 6, value: 21, label: "21, 3 weeks" },
  { id: 7, value: 28, label: "28, 4 weeks" },
  { id: 8, value: 35, label: "35, 5 weeks" },
  { id: 9, value: 42, label: "42, 6 weeks" },
  { id: 10, value: 49, label: "49, 7 weeks" },
  { id: 11, value: 56, label: "56, 8 weeks" },
  { id: 12, value: 63, label: "63, 9 weeks" },
  { id: 13, value: 70, label: "70, 10 weeks" },
  { id: 14, value: 77, label: "77, 11 weeks" },
  { id: 15, value: 84, label: "84, 12 weeks" },
  { id: 16, value: 91, label: "91, 13 weeks" },
  { id: 17, value: 98, label: "98, 14 weeks" },
  { id: 18, value: 105, label: "105, 15 weeks" },
  { id: 19, value: 112, label: "112, 16 weeks" },
  { id: 20, value: 119, label: "119, 17 weeks" },
  { id: 21, value: 126, label: "126, 18 weeks" },
  { id: 22, value: 133, label: "133, 19 weeks" },
  { id: 23, value: 140, label: "140, 20 weeks" },
  { id: 24, value: 10000, label: "No Limit" },
];

export const automationOptions = [
  { id: 1, value: CAMPAIGN_AUTOMATION_LEVEL.GET_ADDRESSES, label: "Get Addresses" },
  { id: 2, value: CAMPAIGN_AUTOMATION_LEVEL.MATCH_ADDRESSES, label: "Match Addresses" },
  { id: 3, value: CAMPAIGN_AUTOMATION_LEVEL.CLEAN_ADDRESSES, label: "Clean Addresses" },
  { id: 4, value: CAMPAIGN_AUTOMATION_LEVEL.GET_OWNERS, label: "Get Owners" },
  { id: 5, value: CAMPAIGN_AUTOMATION_LEVEL.MATCH_OWNERS, label: "Match Owners" },
  { id: 6, value: CAMPAIGN_AUTOMATION_LEVEL.CLEAN_OWNERS, label: "Clean Owners" },
  { id: 7, value: CAMPAIGN_AUTOMATION_LEVEL.GET_PROFILES, label: "Get Profiles" },
  { id: 8, value: CAMPAIGN_AUTOMATION_LEVEL.CLEAN_PROFILES, label: "Clean Profiles" },
  { id: 9, value: CAMPAIGN_AUTOMATION_LEVEL.MATCH_PROFILES, label: "Match Profiles" },
];

export const visibleCampaignTypes = [CAMPAIGN_TYPE.SLOW_MOVER, CAMPAIGN_TYPE.NEW];

export const defaultCommisionCampaignValues = {
  filters: {
    price: { min: { label: "£0", value: 0 }, max: { label: "No Limit", value: 1000000000 } },
    maximumAddresses: { label: "100", value: 100 },
    daysOnMarket: { min: { label: "0", value: 0 }, max: { label: "No Limit", value: 10000 } },
  },
};
