import * as yup from "yup";

export const updateCampaignSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  workspaceId: yup.string().required("Workspace is required"),
  externalStatusId: yup.string().required("Status is required"),
});

export type UpdateCampaignValues = {
  id: number;
  name: string;
  workspaceId: number;
  externalStatusId: number;
};
