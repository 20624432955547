import { useQuery } from "@apollo/client";
import { getWorkspaces } from "../queries/getWorkspaces";
import { GetWorkspacesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { WORKSPACE_STATUS } from "../../../../constants/models";

export type UseWorkspaces = ReturnType<typeof useWorkspaces>;

export const useWorkspaces = (
  { orderBy }: { orderBy: Record<string, string>[] } = {
    orderBy: [],
  }
) => {
  const { data, loading, error, refetch } = useQuery<GetWorkspacesQuery>(getWorkspaces, {
    variables: {
      orderBy,
    },
  });
  return {
    workspaces: data && Array.isArray(data.workspace) ? data.workspace : [],
    activeWorkspaces:
      data && Array.isArray(data.workspace)
        ? data.workspace.filter(
            workspace => workspace.workspace_status_id === WORKSPACE_STATUS.ACTIVE
          )
        : [],
    workspacesError: error,
    workspacesLoading: loading,
    refetchWorkspaces: refetch,
  };
};
