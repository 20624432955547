import { getOutreachPlatforms } from "../queries/getOutreachPlatforms";
import { GetOutreachPlatformsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseOutreachPlatforms = ReturnType<typeof useOutreachPlatforms>;

export const useOutreachPlatforms = () => {
  const { data, loading, error } = useQuery<GetOutreachPlatformsQuery>(getOutreachPlatforms);
  return {
    outreachPlatforms: data && Array.isArray(data.outreach_platform) ? data.outreach_platform : [],
    outreachPlatformsError: error,
    outreachPlatformsLoading: loading,
  };
};
