import * as yup from "yup";

// TODO: inferType is not working
export type CreateWorkspaceValues = {
  name: string;
  workspaceStatusId: string;
};

export const createWorkspaceValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  workspaceStatusId: yup.string().required("Status is required"),
});
