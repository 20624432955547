import { gql } from "@apollo/client";

export const getBranchAgents = gql`
  query getBranchAgents {
    branch_agent {
      id
      name
    }
  }
`;
