import * as yup from "yup";

export interface CommisionCampaignValues {
  // SCREEN 1
  workspace: { label: string; value: number };
  outreachAgents: { label: string; value: number }[];
  campaignTypeId: number;

  // SCREEN 2
  postcodeDistricts: { label: string; value: number }[];
  filters: {
    price: { min: { label: string; value: number }; max: { label: string; value: number } };
    maximumAddresses: { label: string; value: number };
    daysOnMarket: { min: { label: string; value: number }; max: { label: string; value: number } };
  };

  // SCREEN 3

  messageSetId: number;

  // SCREEN 4

  enabledOwnershipSources: { label: string; value: number }[];
  automationLevel: { label: string; value: number };

  // SCREEN 5

  campaignName: string;
}

export const commissionCampaignSchema = yup.object().shape({
  // SCREEN 1 = WorkspaceId + OutreachAgentId + campaignTypeId
  workspace: yup
    .object()
    .shape({
      label: yup.string().required(),
      value: yup.number().required(),
    })
    .typeError("Please select a workspace"),

  outreachAgents: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .typeError("Please select at least one Channel")
    )
    .min(1, "Please select at least one Channel")
    .required("Please select at least one Channel")
    .defined("Please select at least one Channel"),
  campaignTypeId: yup.number().required("Please select a campaign Type").defined(),

  // SCREEN 2 = districtIds + maximumAddresses + minDaysOnMarket + maxDaysOnMarket + minPrice + maxPrice
  postcodeDistricts: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .typeError("Please select at least one Postcode District")
    )
    .min(1, "Please select at least one Postcode District")
    .required("Please select at least one Postcode District")
    .defined("Please select at least one Postcode District"),
  filters: yup
    .object({
      price: yup
        .object({
          min: yup
            .object()
            .shape({
              label: yup.string().required("min price is required"),
            })
            .typeError("min price is required"),
          max: yup
            .object()
            .shape({
              label: yup.string().required("Max price is required"),
            })
            .typeError("Max price is required"),
        })
        .defined()
        .required(),
      maximumAddresses: yup
        .object()
        .shape({
          label: yup.string().required("max Addresses is required"),
        })
        .typeError("max Addresses is required"),
      daysOnMarket: yup
        .object({
          min: yup
            .object()
            .shape({
              label: yup.string().required("Min Days on Market is required"),
            })
            .typeError("Min Days on Market is required"),
          max: yup
            .object()
            .shape({
              label: yup.string().required("Max Days on Market is required"),
            })
            .typeError("Max Days on Market is required"),
        })
        .defined()
        .required(),
    })
    .defined()
    .required(),

  // SCREEN 3 = MessageSetId
  messageSetId: yup.number().defined().required("Sequence is required"),

  // SCREEN 4 = enabledOwnershipSourceIds + automationLevelId
  enabledOwnershipSources: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .typeError("Please select at least one Ownership Source")
    )
    .min(1, "Please select at least one Ownership Source")
    .required("Please select at least one Ownership Source")
    .defined("Please select at least one Ownership Source"),
  automationLevel: yup
    .object()
    .shape({
      label: yup.string().required("Automation Level is required"),
    })
    .typeError("Automation Level is required"),

  // SCREEN 5 = campaignName
  campaignName: yup.string().required("Campaign name is required"),
});
