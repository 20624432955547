import { getOwnershipSources } from "../queries/getOwnershipSources";
import { GetOwnershipSourcesQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseOwnershipSources = ReturnType<typeof useOwnershipSources>;

export const useOwnershipSources = () => {
  const { data, loading, error } = useQuery<GetOwnershipSourcesQuery>(getOwnershipSources);
  return {
    ownershipSources: data && Array.isArray(data.ownership_source) ? data.ownership_source : [],
    ownershipSourcesError: error,
    ownershipSourcesLoading: loading,
  };
};
