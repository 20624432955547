import { gql } from "@apollo/client";

export const getOwnershipSources = gql`
  query getOwnershipSources {
    ownership_source {
      id
      name
    }
  }
`;
