import { gql } from "@apollo/client";

export const getWorkspaceStatuses = gql`
  query getWorkspaceStatuses {
    workspace_status {
      id
      name
    }
  }
`;
