import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { meetmoreAPI } from "../../../../infrastructure/APIs/meetmoreAPI";

export interface AddOutreachAgentBody {
  platformIdentifier: string;
  outreachPlatformId: number;
  externalId: number;
  externalCredentialId?: string;
  outreachAgentTypeId: number;
  providerId: number;
  isActive: boolean;
  workspaceId: number;
}

export const addOutreachAgentAPI = async (data: AddOutreachAgentBody) => {
  const response = await meetmoreAPI.post(`/outreach-agents`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

export const useAddOutreachAgentAPI = () => {
  let loadingToastId: string;

  return useMutation(addOutreachAgentAPI, {
    onMutate: () => {
      // Popup a toast here to inform user that the mutation is in progress
      loadingToastId = toast.loading("Creating Channel...");
      // Return loadingToastId so it can be used in onSettled
    },
    onSuccess: async (data, variables, context) => {
      // Notify the user of the successful mutation
      toast.success("Channel Created!");
    },
    onError: (error: any, variables, context) => {
      // Notify the user if the mutation fails
      toast.error(`Error: ${error?.response?.data?.errors[0]?.message ?? error?.message}`);
    },
    onSettled: (data, error, variables, context) => {
      // Dismiss the loading toast regardless of success or failure
      if (loadingToastId) {
        toast.dismiss(loadingToastId);
      }
    },
  });
};
