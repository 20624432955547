import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "../../../../shared/components/button/Button";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { turnstoneStyles } from "../../../../shared/components/typeahead/styles";
import Typeahead from "../../../../shared/components/typeahead/Typeahead";
import { useLazyPostcodeUnitByCode } from "../../../../shared/domains/postcodes/graphql/hooks/usePostcodeUnitByCode";
import { useLazyAddressesByPostcodeUnitIds } from "../../../../shared/domains/postcodes/graphql/hooks/useAddressesByPostcodeUnitIds";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getSuspectedOwnersFromLandRegistrySchema,
  GetSuspectedOwnersFromLandRegistrySchema,
} from "../validation";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useGetPropertyTitlesBaseOnAddressIdAPI } from "../../../../shared/domains/suspectedOwners/rest/getPropertyTitlesByAddressId";
import { useGetSuspectedOwnersAPI } from "../../../../shared/domains/suspectedOwners/rest/getSuspectedOwners";

export default function GetOwners() {
  const [postcodeUnit, setPostcodeUnit] = useState<any | null>(null);
  const [selectedAddressId, setSelectedAddressId] = useState<any | null>(null);
  const [suspectedOwners, setSuspectedOwners] = useState<any[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [titleOptions, setTitleOptions] = useState<
    {
      titleNumber: string;
      titleClass: string;
    }[]
  >([]);

  const { mutateAsync: getPropertyTitlesByAddressId } = useGetPropertyTitlesBaseOnAddressIdAPI();

  const { getLazyPostcodeUnitsByCode } = useLazyPostcodeUnitByCode();

  const { getLazyAddressesByPostcodeUnitIds, aggregatedAddresses } =
    useLazyAddressesByPostcodeUnitIds();

  const { isLoading: isLoadingGetSuspectedOwners, mutateAsync: getSuspectedOwnersFromLandRegistry } = useGetSuspectedOwnersAPI();

  useEffect(() => {
    if (selectedAddressId) {
      const data = getPropertyTitlesByAddressId({ addressId: selectedAddressId }).then(data => {
        console.log("data", data);
        setTitleOptions(data.propertyTitles);
      });
    }
  }, [selectedAddressId]);

  useEffect(() => {
    if (postcodeUnit) {
      getLazyAddressesByPostcodeUnitIds([postcodeUnit]);
    }
  }, [postcodeUnit]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<GetSuspectedOwnersFromLandRegistrySchema>({
    resolver: yupResolver(getSuspectedOwnersFromLandRegistrySchema),
  });

  const selectPostcodeUnit = async (item: { id?: number; code?: string }) => {
    if (item?.id) {
      setPostcodeUnit(item.id);
    } else {
      reset();
      setPostcodeUnit(null);
    }
  };

  const addresses = aggregatedAddresses
    .flatMap(address => address.addresses)
    .map(address => ({
      id: address.id,
      fullAddresss: address.fullAddress,
      uprn: address.uprn,
    }));

  const onSubmit = async (data: GetSuspectedOwnersFromLandRegistrySchema) => {
    console.log("data", data);
    setFormSubmitted(true);
    console.log("addresses", addresses);
    console.log("selectedAddressId", selectedAddressId);
    const uprn = addresses.find(address => Number(address.id) === Number(selectedAddressId))?.uprn;
    if (!uprn) {
      alert("Uprn not found");
      return;
    }
    const responseData = await getSuspectedOwnersFromLandRegistry({
      uprn: uprn,
      email: data.email,
      propertyTitles: [data.propertyTitle],
    });

    console.log("responseData", responseData.suspectedOwners);

    setSuspectedOwners(responseData.suspectedOwners);
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Get Owners
          </h1>
        </div>
      </div>
      <section aria-labelledby="get-owners-heading">
        <div className="grid grid-cols-1 gap-x-14 gap-y-8 md:grid-cols-4">
          <div className="md:col-span-2">
            <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Label htmlFor="postcode-unit" className="font-medium">
                  Postcode Unit
                </Label>
                <Typeahead
                  styles={turnstoneStyles}
                  maxItems={10}
                  onSelect={selectPostcodeUnit}
                  placeholder="Enter a Postcode Unit"
                  listbox={{
                    id: "postcodeUnits",
                    name: "Postcode Units",
                    displayField: "code",
                    data: async (query: string) => {
                      const results = await getLazyPostcodeUnitsByCode({
                        code: query,
                        limit: 10,
                      });
                      return results;
                    },
                    searchType: "startswith",
                  }}
                  id="postcodeUnitSelector"
                  name="postcodeUnitSelector"
                />
              </div>
              {postcodeUnit ? (
                <div>
                  <div className="mt-2">
                    <Label htmlFor="title" className="font-medium">
                      Select Address
                    </Label>
                    <Controller
                      name="uprn"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={field.value} // Ensure value is set
                          onValueChange={value => {
                            field.onChange(value);
                            setSelectedAddressId(value);
                          }}
                        >
                          <SelectTrigger className="mt-2">
                            <SelectValue placeholder="Select Address" />
                          </SelectTrigger>
                          <SelectContent>
                            {addresses.map(address => (
                              <SelectItem key={address.id} value={address.id.toString()}>
                                {address.fullAddresss}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                    {errors.uprn && <ErrorMessage>{errors.uprn?.message}</ErrorMessage>}
                  </div>
                  {selectedAddressId && (
                    <>
                      <div className="mt-2">
                        <Label htmlFor="title" className="font-medium">
                          Property Title
                        </Label>
                        <Controller
                          name="propertyTitle"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={field.value} // Ensure value is set
                              onValueChange={value => {
                                field.onChange(value);
                              }}
                            >
                              <SelectTrigger className="mt-2">
                                <SelectValue placeholder="Select Property Title" />
                              </SelectTrigger>
                              <SelectContent>
                                {titleOptions.map(title => (
                                  <SelectItem
                                    key={title.titleNumber}
                                    value={title.titleNumber.toString()}
                                  >
                                    {title.titleNumber} - {title.titleClass}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          )}
                        />
                        {errors.propertyTitle && (
                          <ErrorMessage>{errors.propertyTitle?.message}</ErrorMessage>
                        )}
                      </div>

                      <div className="mt-2">
                        <Label htmlFor="email" className="font-medium">
                          Email
                        </Label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Email"
                              onChange={e => field.onChange(e.target.value)}
                              className="mt-2"
                            />
                          )}
                        />
                        {errors.email && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
                      </div>
                    </>
                  )}
                  <div className="mt-2 flex justify-end">
                    <Button disabled={isLoadingGetSuspectedOwners} type="submit">Submit</Button>
                  </div>
                </div>
              ) : null}
            </form>
            <div>
              {formSubmitted && suspectedOwners.length ? (
                <div className="mt-2">
                  <Label htmlFor="title" className="font-medium">
                    Suspected Owners
                  </Label>
                  <div className="mt-2">
                    {suspectedOwners.map(owner => (
                      <div key={owner?.id} className="flex items-center gap-2">
                        <span>First Name: {owner.firstName}</span>
                        <span>Last Name: {owner.lastName}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
