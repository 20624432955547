import { useQuery, useLazyQuery } from "@apollo/client";
import { GetOutreachAgentsByWorkspaceIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getOutreachAgentsByWorkspaceId } from "../queries/getOutreachAgentsByWorkspaceId";

export type UseOutreachAgentsByWorkspaceId = ReturnType<typeof useOutreachAgentsByWorkspaceId>;

export const useOutreachAgentsByWorkspaceId = ({ workspaceId }: { workspaceId: number }) => {
  const { data, loading, error, refetch } = useQuery<GetOutreachAgentsByWorkspaceIdQuery>(
    getOutreachAgentsByWorkspaceId,
    {
      variables: {
        id: workspaceId,
      },
    }
  );
  return {
    outreachAgents: data && Array.isArray(data.outreach_agent) ? data.outreach_agent : [],
    activeOutreachAgents:
      data && Array.isArray(data.outreach_agent)
        ? data.outreach_agent.filter(agent => agent.is_active)
        : [],
    inActiveOutreachAgents:
      data && Array.isArray(data.outreach_agent)
        ? data.outreach_agent.filter(agent => !agent.is_active)
        : [],
    outreachAgentsError: error,
    outreachAgentsLoading: loading,
    refetchOutreachAgents: async () => {
      await refetch();
    },
  };
};

export type UseLazyOutreachAgentsByWorkspaceId = ReturnType<
  typeof useLazyOutreachAgentsByWorkspaceId
>;

export const useLazyOutreachAgentsByWorkspaceId = () => {
  const [fetchOutreachAgents, { data, loading, error }] =
    useLazyQuery<GetOutreachAgentsByWorkspaceIdQuery>(getOutreachAgentsByWorkspaceId);

  return {
    fetchOutreachAgents,
    outreachAgents: data && Array.isArray(data.outreach_agent) ? data.outreach_agent : [],
    activeOutreachAgents:
      data && Array.isArray(data.outreach_agent)
        ? data.outreach_agent.filter(agent => agent.is_active)
        : [],
    verifiedOutreachAgents:
      data && Array.isArray(data.outreach_agent)
        ? data.outreach_agent.filter(agent => agent.is_verified)
        : [],
    inActiveOutreachAgents:
      data && Array.isArray(data.outreach_agent)
        ? data.outreach_agent.filter(agent => !agent.is_active)
        : [],
    outreachAgentsError: error,
    outreachAgentsLoading: loading,
  };
};
