import { gql } from "@apollo/client";

export const getCampaignTypes = gql`
  query getCampaignTypes {
    campaign_type {
      id
      name
      description
    }
  }
`;
