import { getBranchAgents } from "../queries/getBranchAgents";
import { GetBranchAgentsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery, useLazyQuery } from "@apollo/client";

export type UseBranchAgents = ReturnType<typeof useBranchAgents>;
export type UseLazyBranchAgents = ReturnType<typeof useBranchAgentsLazy>;

// Normal useBranchAgents hook
export const useBranchAgents = () => {
  const { data, loading, error } = useQuery<GetBranchAgentsQuery>(getBranchAgents);
  return {
    branchAgents: data && Array.isArray(data.branch_agent) ? data.branch_agent : [],
    branchAgentsError: error,
    branchAgentsLoading: loading,
  };
};

// Lazy useLazyBranchAgents hook
export const useBranchAgentsLazy = () => {
  const [fetchBranchAgents, { data, loading, error }] =
    useLazyQuery<GetBranchAgentsQuery>(getBranchAgents);

  return {
    fetchBranchAgents,
    branchAgents: data && Array.isArray(data.branch_agent) ? data.branch_agent : [],
    branchAgentsError: error,
    branchAgentsLoading: loading,
  };
};
