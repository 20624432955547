import { useEffect, useState } from "react";
import { getColumns } from "../components/Columns";
import { DataTable } from "../components/DataTable";
import { PAGE_SIZE } from "../data";
import { ColumnSort } from "@tanstack/react-table";
import { translateSortingQuery } from "../utils/index";
import { useGetCampaigns } from "../../../../shared/domains/campaigns/graphql/hooks/useGetCampaigns";
import { useGetCampaignById } from "../../../../shared/domains/campaigns/graphql/hooks/useGetCampaignById";
import { DataTableDrawer } from "../components/DataTableDrawer";
import { useWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { UpdateCampaignValues } from "../validation";
import { useCampaignExternalStatuses } from "../../../../shared/domains/static/graphql/hooks/useCampaignExternalStatuses";
import { useUpdateCampaignAPI } from "../../../../shared/domains/campaigns/rest/updateCampaignAPI";
import { Button } from "../../../../shared/components/button/Button";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { useSyncCampaignAPI } from "../../../../shared/domains/campaigns/rest/syncCampaignAPI";
import { useCampaignInternalStatuses } from "../../../../shared/domains/static/graphql/hooks/useCampaignInternalStatuses";

export default function Campaigns() {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false);
  const [workspaceIds, setWorkspaceIds] = useState<number[]>([]);
  const [internalStatusIds, setInternalStatusIds] = useState<number[]>([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { campaign, fetchCampaignById } = useGetCampaignById();
  const { workspaces } = useWorkspaces({
    orderBy: [{ name: "asc" }],
  });
  const navigate = useNavigate();
  const { mutateAsync: syncCampaignAPI } = useSyncCampaignAPI();

  const columns = getColumns({
    onEditClick: async row => {
      setIsDrawerOpen(true);
      await fetchCampaignById({ variables: { id: row.original.id } });
    },
    onManageClick: row => {
      window.open(`https://old-app.meetmore.ai/campaigns/${row.original.id}`, "_blank");
    },
    onSyncClick: async row => {
      await syncCampaignAPI({ campaignId: row.original.id });
    },
  });

  useEffect(() => {
    if (workspaces.length > 0) {
      setWorkspaceIds(workspaces.map(workspace => workspace.id));
    }
  }, [workspaces]);

  const { campaignInternalStatuses } = useCampaignInternalStatuses();

  useEffect(() => {
    if (campaignInternalStatuses.length > 0) {
      setInternalStatusIds(campaignInternalStatuses.map(status => status.id));
    }
  }, [campaignInternalStatuses.length]);

  const addNewCampaign = () => {
    navigate(`/campaigns/commission`);
  };

  const { campaigns, campaignsCount, fetchCampaigns } = useGetCampaigns();
  const { campaignExternalStatuses } = useCampaignExternalStatuses();

  const { offset, setOffset } = usePagination({
    pageSize: PAGE_SIZE,
    totalRecords: campaignsCount,
  });
  const { mutateAsync: updateCampaignAPI, isSuccess } = useUpdateCampaignAPI();

  useEffect(() => {
    const translatedSorting = translateSortingQuery({ sorting });
    fetchCampaigns({
      variables: {
        limit: PAGE_SIZE,
        offset: offset,
        orderBy: translatedSorting,
        workspacesIds: workspaceIds,
        internalStatusIds,
      },
    });
  }, [offset, sorting, isSuccess, workspaceIds, internalStatusIds]);

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  const handleUpdateCampaign = async (data: UpdateCampaignValues) => {
    setIsDrawerOpen(false);
    await updateCampaignAPI(data);
  };

  const onWorkspacesChange = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setWorkspaceIds(workspaces.map(workspace => workspace.id));
      setOffset(0);
    } else {
      setWorkspaceIds(selectedIds);
      setOffset(0);
    }
    setTriggerFilter(false);
  };

  const onInternalStatusChange = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setInternalStatusIds(campaignInternalStatuses.map(internalStatus => internalStatus.id));
      setOffset(0);
    } else {
      setInternalStatusIds(selectedIds);
      setOffset(0);
    }
    setTriggerFilter(false);
  };

  const workspaceOptions = workspaces.map((workspace: { id: number; name: string }) => ({
    id: workspace.id,
    name: workspace.name,
  }));

  const internalStatuses = campaignInternalStatuses.map(
    (internalStatus: { id: number; name: string }) => ({
      id: internalStatus.id,
      name: internalStatus.name,
    })
  );

  const resetFilters = () => {
    setWorkspaceIds(workspaces.map(workspace => workspace.id));
    setInternalStatusIds(campaignInternalStatuses.map(internalStatus => internalStatus.id));
    setOffset(0);
    setTriggerFilter(true);
  };

  const isFiltered =
    workspaceIds.length !== workspaces.length ||
    internalStatusIds.length !== campaignInternalStatuses.length;

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Campaigns
          </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">
            View and manage all your campaigns in one place.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button className="w-full gap-2 sm:w-fit" onClick={addNewCampaign}>
            <Plus className="-ml-1 size-4 shrink-0" aria-hidden="true" />
            Commission Campaign
          </Button>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          data={campaigns}
          columns={columns}
          triggerFilter={triggerFilter}
          isFiltered={isFiltered}
          resetFilters={resetFilters}
          internalStatuses={internalStatuses}
          workspaceOptions={workspaceOptions}
          onWorkspaceChange={onWorkspacesChange}
          onInternalStatusChange={onInternalStatusChange}
          totalRecords={campaignsCount}
          offset={offset}
          setSorting={setSorting}
          sorting={sorting}
          onPaginationChange={handleOnPaginationChange}
        />
        {campaign && (
          <DataTableDrawer
            open={isDrawerOpen}
            onUpdateCampaign={handleUpdateCampaign}
            onOpenChange={setIsDrawerOpen}
            data={campaign}
            workspaces={workspaces}
            campaignStatuses={campaignExternalStatuses}
          />
        )}
      </div>
    </>
  );
}
