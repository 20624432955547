import React, { useEffect } from "react";
import { useWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import { getColumns } from "../components/Columns";
import { DataTable } from "../components/DataTable";
import { Button } from "../../../../shared/components/button/Button";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Input } from "../../../../shared/components/input/Input";
import { Label } from "../../../../shared/components/label/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { useWorkspaceStatuses } from "../../../../shared/domains/static/graphql/hooks/useWorkspaceStatuses";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createWorkspaceValidationSchema, CreateWorkspaceValues } from "../validation";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import { useAddWorkspaceAPI } from "../../../../shared/domains/workspaces/REST/addWorkspaceAPI";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { useWorkspacesPaginated } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspacesPaginated";

export default function Workspaces() {
  const { workspaces, workspacesCount, refetchWorkspaces } = useWorkspacesPaginated();
  const { workspaceStatuses } = useWorkspaceStatuses();

  const { offset, setOffset } = usePagination({
    pageSize: 10,
    totalRecords: workspacesCount,
  });

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  useEffect(() => {
    refetchWorkspaces({
      limit: 10,
      offset,
    });
  }, [offset]);

  const columns = getColumns();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const {
    mutateAsync: addWorkspaceMutation,
    isSuccess: addWorkspaceMutationSuccess,
    isLoading: addWorkspaceMutationLoading,
  } = useAddWorkspaceAPI();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CreateWorkspaceValues>({
    resolver: yupResolver(createWorkspaceValidationSchema),
  });
  useEffect(() => {
    if (addWorkspaceMutationSuccess) {
      refetchWorkspaces({
        limit: 10,
        offset: 0,
      });
      setIsDialogOpen(false);
      reset(undefined);
    }
  }, [addWorkspaceMutationSuccess]);

  const onSubmit = (data: CreateWorkspaceValues) => {
    addWorkspaceMutation({
      workspaceName: data.name,
      workspaceStatusId: Number(data.workspaceStatusId),
    });
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Workspaces
          </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">
            A list of all workspaces. Each agency should have its own workspace.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button className="w-full gap-2 sm:w-fit">
                <Plus className="-ml-1 size-4 shrink-0" aria-hidden="true" />
                Add Workspace
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-lg max-h-screen sm:max-h-[90vh]">
              <DialogHeader>
                <DialogTitle>Add New Workspace</DialogTitle>
                <DialogDescription className="mt-1 text-sm leading-6">
                  Fill in the details below to add a new workspace.
                </DialogDescription>
              </DialogHeader>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4">
                <div>
                  <Label htmlFor="name" className="font-medium">
                    Name
                  </Label>
                  <Input {...register("name")} type="text" className="mt-2" placeholder="name" />
                  {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                </div>
                <div>
                  <Label htmlFor="Workspace-status" className="font-medium">
                    Status
                  </Label>
                  <Controller
                    control={control}
                    name="workspaceStatusId"
                    render={({ field }) => {
                      return (
                        <Select onValueChange={field.onChange} {...field}>
                          <SelectTrigger id="Workspace-status" className="mt-2 w-full">
                            <SelectValue placeholder="Workspace Status" />
                          </SelectTrigger>
                          <SelectContent>
                            {workspaceStatuses.map(item => (
                              <SelectItem key={item.id} value={item.id?.toString()}>
                                {item.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.workspaceStatusId && (
                    <ErrorMessage>{errors.workspaceStatusId.message}</ErrorMessage>
                  )}
                </div>
                <DialogFooter className="mt-6">
                  <DialogClose asChild>
                    <Button className="mt-2 w-full sm:mt-0 sm:w-fit" variant="secondary">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    className="w-full sm:w-fit"
                    variant="primary"
                    type="submit"
                    disabled={addWorkspaceMutationLoading}
                  >
                    Add Workspace
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          data={workspaces}
          columns={columns}
          offset={offset}
          onPaginationChange={handleOnPaginationChange}
          totalRecords={workspacesCount}
          onRowClick={row => {
            navigate(`/workspaces/${row.original.id}/general`);
          }}
        />
      </div>
    </>
  );
}
