import { gql } from "@apollo/client";

export const getWorkspaceById = gql`
  query getWorkspaceById($id: Int!) {
    workspace_by_pk(id: $id) {
      id
      name
      workspace_status_id
      workspace_users {
        user {
          email
          name
          id
        }
      }
      workspace_branch_agent_blacklists {
        branch_agent {
          id
          name
          parent_agent {
            id
            name
          }
          agent_provider {
            id
            name
          }
        }
      }
    }
  }
`;
