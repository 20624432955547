import { gql } from "@apollo/client";

export const getOutreachAgentTypes = gql`
  query getOutreachAgentTypes {
    outreach_agent_type {
      id
      name
    }
  }
`;
