import {
  UseFormSetValue,
  UseFormTrigger,
  UseFormRegister,
  FieldErrorsImpl,
  DeepRequired,
  Control,
  UseFormWatch,
  UseFormReset,
  UseFormResetField,
} from "react-hook-form";
import { CommisionCampaignValues } from "../validation";
import { UseMessageSets } from "../../../../../shared/domains/messageSets/graphql/hooks/useMessageSets";
import { useState } from "react";
import { ArrayElement } from "../../../../../shared/utils";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import ErrorMessage from "../../../../../shared/components/errorMessage/ErrorMessage";
import ViewingTemplates from "./historicalMessaging/ViewingTemplates";
import ViewingMessageSet from "./historicalMessaging/ViewingMessageSet";

interface SequencesProps {
  register: UseFormRegister<CommisionCampaignValues>;
  setValue: UseFormSetValue<CommisionCampaignValues>;
  errors: FieldErrorsImpl<DeepRequired<CommisionCampaignValues>>;
  nextStep: () => Promise<void>;
  trigger: UseFormTrigger<CommisionCampaignValues>;
  watch: UseFormWatch<CommisionCampaignValues>;
  control: Control<CommisionCampaignValues, any>;
  previousStep: () => Promise<void>;
  reset: UseFormReset<CommisionCampaignValues>;
  messageSets: UseMessageSets["messageSets"];
  resetField: UseFormResetField<CommisionCampaignValues>;
  currentStep: number;
}

// key logic here is if you are creating new messages that exists in the messages array in form, otherwise keep somewhere else
const Sequences = ({
  setValue,
  errors,
  reset,
  resetField,
  register,
  trigger,
  messageSets,
  currentStep,

  previousStep,
  watch,
  nextStep,
}: SequencesProps) => {
  const selectedMessageSetId = watch("messageSetId");

  const selectNewTemplate = async (messageSet: ArrayElement<UseMessageSets["messageSets"]>) => {
    setValue("messageSetId", messageSet.id);
  };

  console.log(selectedMessageSetId, "selectedMessageSetId");
  const validateAndNext = async () => {
    const result = await trigger(["messageSetId"]);
    if (result) {
      nextStep();
    }
  };

  return (
    <>
      <div className="bg-gray-50 pb-20 min-h-screen h-full flex-wrap flex flex-col items-center justify-center focus:outline-none">
        <div className="relative w-full mx-auto px-8 pt-10 pb-8 md:px-10 xl:px-12">
          <div className=" max-w-3xl mx-auto space-y-8">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {currentStep}. Please set your <span className="text-orange-500">Messaging</span>.
              </h2>
              <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-orange-700">
                      Please remember <span className="italic">'day(s) later'</span> refers to days
                      after the previous message.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {selectedMessageSetId && (
            <ViewingMessageSet
              resetField={resetField}
              setValue={setValue}
              messageSetId={selectedMessageSetId}
              messageSets={messageSets}
              previousStep={previousStep}
              validateAndNext={validateAndNext}
              watch={watch}
              reset={reset}
              errors={errors}
            />
          )}

          {!selectedMessageSetId && (
            <ViewingTemplates
              setValue={setValue}
              previousStep={previousStep}
              validateAndNext={validateAndNext}
              messageSets={messageSets}
              selectNewTemplate={selectNewTemplate}
            />
          )}

          {errors.messageSetId && (
            <ErrorMessage> Please ensure you have messages set </ErrorMessage>
          )}
        </div>
      </div>
    </>
  );
};

export default Sequences;
