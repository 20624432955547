import { useQuery } from "@apollo/client";
import { GetAdminUserByExternalIdQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { getAdminUserByExternalId } from "../queries/getAdminUserByExternalId";

export const useIsUserAdmin = ({ externalId }: { externalId: string }) => {
  const { data, loading, error } = useQuery<GetAdminUserByExternalIdQuery>(
    getAdminUserByExternalId,
    {
      variables: { externalId },
    }
  );

  return {
    isAdmin: data && data.workspace_user ? data.workspace_user.length > 0 : false,
    loading,
    error,
  };
};
