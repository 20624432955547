import { getUsers } from "../queries/getUsers";
import { GetUsersQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseUsers = ReturnType<typeof useUsers>;

export const useUsers = () => {
  const { data, loading, error } = useQuery<GetUsersQuery>(getUsers);
  return {
    users: data && Array.isArray(data.user) ? data.user : [],
    usersError: error,
    usersLoading: loading,
  };
};
