import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

export const reactSelectStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#8D8D8D",
  }),
  menu: (providedStyling: any) => ({
    ...providedStyling,
    position: "absolute",
  }),
  menuList: (providedStyling: any) => ({
    ...providedStyling,
    position: "relative",
  }),
};
