import { gql } from "@apollo/client";

export const getCampaignLeadProfileById = gql`
  query getCampaignLeadProfileById($id: Int!) {
    campaign_lead_profile_by_pk(id: $id) {
      external_id
      id
      external_linkedin_user_id
      # campaign -- Address Level Infor
      campaign_lead {
        address {
          full_address
        }
        stated_address
        agent
        listed_at
        public_url
        campaign {
          outreach_agent {
            external_id
          }
          campaign_type {
            id
            name
          }
          workspace {
            name
          }
        }
      }

      # Owner level info

      owner_address_profile {
        suspected_owner_address {
          suspected_owner {
            first_name
            last_name
            name
            stated_address
            ownership_source {
              id
              name
            }
          }
        }
      }

      # Profile level info
      digital_profile {
        id
        name
        value
        digital_profile_type {
          id
          name
        }
        primary_additional_info
      }
      campaign_lead_profile_outreach_status {
        name
        id
      }
      connected_at
      connection_degree
      invited_to_connect_at
      phone_number
      personal_email
      business_email

      ## conversation value related
      conversation_value
      campaign_lead_profile_property_values(order_by: { id: asc }) {
        id
        property_value
        campaign_lead_profile_property_value_source_type {
          id
          name
        }
      }
      campaign_lead_profile_sale_action_statuses(order_by: { id: asc }) {
        id
        sale_action_status {
          id
          name
        }
        campaign_lead_profile_sale_action_status_source_type {
          id
          name
        }
      }
      ## conversation thread related
      conversation_thread {
        id
        is_managed_by_user: user {
          email
          id
          name
        }
        conversation_messages {
          conversation_message_status
          id
          is_outbound
          admin_seen_at
          content
          external_created_at
        }
      }

      ## outstanding tags
      is_incorrect_match
      is_personal_connection
      is_do_not_message
      is_finished_executing_steps
    }
  }
`;
