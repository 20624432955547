import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { ChatBubbleBottomCenterTextIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { User } from "firebase/auth";
import { useState } from "react";
import TemplateSelectorModal from "./TemplateSelectorModal";
import { CommisionCampaignValues } from "../../validation";
import { UseMessageSets } from "../../../../../../shared/domains/messageSets/graphql/hooks/useMessageSets";
import { ArrayElement, classNames } from "../../../../../../shared/utils";

interface ViewingTemplatesProps {
  setValue: UseFormSetValue<CommisionCampaignValues>;
  messageSets: UseMessageSets["messageSets"];
  previousStep: () => void;
  validateAndNext: () => void;
  selectNewTemplate: (messageSet: ArrayElement<UseMessageSets["messageSets"]>) => Promise<void>;
}

const ViewingTemplates = ({
  messageSets,
  selectNewTemplate,
  previousStep,
  validateAndNext,
}: ViewingTemplatesProps) => {
  const [selectedMessageSet, setSelectedMessageSet] = useState<ArrayElement<
    UseMessageSets["messageSets"]
  > | null>(null);
  const [isTemplateSelectorPanelOpen, setIsTemplateSelectorPanelOpen] = useState(false);

  return (
    <>
      {selectedMessageSet && (
        <TemplateSelectorModal
          messageSet={selectedMessageSet}
          isOpen={isTemplateSelectorPanelOpen}
          setOpen={setIsTemplateSelectorPanelOpen}
          selectNewTemplate={selectNewTemplate}
        />
      )}
      <div className="max-w-3xl mx-auto">
        <div className="shadow sm:rounded-md">
          <div className="space-y-6 bg-white mt-5 px-4 ">
            <div className="w-full">
              <div className="border-b border-gray-100 bg-white  py-5 ">
                <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-4">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                      Sequence Templates:{" "}
                      <span className="ml-2 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-600/20">
                        {messageSets.length}
                      </span>
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Please select a template to use as this campaigns sequence
                    </p>
                  </div>
                </div>
              </div>

              <ul role="list" className=" divide-y divide-gray-200 border-b  border-gray-200">
                {messageSets.map(messageSet => (
                  <li
                    onClick={() => {
                      setSelectedMessageSet(messageSet);
                      setIsTemplateSelectorPanelOpen(true);
                    }}
                    className="cursor-pointer"
                    key={messageSet.id}
                  >
                    <div className="group relative flex items-start space-x-3 py-4">
                      <div className="flex-shrink-0">
                        <span
                          className={classNames(
                            "bg-orange-500",
                            "inline-flex h-10 w-10 items-center justify-center rounded-lg"
                          )}
                        >
                          <ChatBubbleBottomCenterTextIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="text-sm font-medium text-gray-900">
                          <div>
                            <span className="absolute inset-0" aria-hidden="true" />
                            {messageSet.name}
                          </div>
                        </div>
                        <p className="text-sm text-gray-500">
                          {messageSet.messages.find(message => message.msg_order === 1)?.content}
                        </p>
                      </div>
                      <div className="flex-shrink-0 self-center">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="py-3 mt-4">
          <div className="flex space-x-6">
            <button
              type="button"
              onClick={() => previousStep()}
              className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => validateAndNext()}
              className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewingTemplates;
