import { gql } from "@apollo/client";

export const getMessageSets = gql`
  query getMessageSets {
    message_set {
      id
      name
      messages {
        content
        id
        msg_order
        send_after_days
      }
    }
  }
`;
