import { gql } from "@apollo/client";

export const getOutreachPlatforms = gql`
  query getOutreachPlatforms {
    outreach_platform {
      id
      name
    }
  }
`;
