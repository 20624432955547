import { gql } from "@apollo/client";

export const getCampaignById = gql`
  query getCampaignById($id: Int!) {
    campaign_by_pk(id: $id) {
      id
      name
      workspace_id
      campaign_status_id
    } 
  }`;
