import { getCampaignExternalConnectionProviders } from "../queries/getCampaignExternalConnectionProviders";
import { GetCampaignExternalConnectionProvidersQuery } from "../../../../infrastructure/graphql/core/generatedTypes";
import { useQuery } from "@apollo/client";

export type UseCampaignExternalConnectionProviders = ReturnType<
  typeof useCampaignExternalConnectionProviders
>;

export const useCampaignExternalConnectionProviders = () => {
  const { data, loading, error } = useQuery<GetCampaignExternalConnectionProvidersQuery>(
    getCampaignExternalConnectionProviders
  );
  return {
    campaignExternalConnectionProviders:
      data && Array.isArray(data.campaign_external_connection_provider)
        ? data.campaign_external_connection_provider
        : [],
    campaignExternalConnectionProvidersError: error,
    campaignExternalConnectionProvidersLoading: loading,
  };
};
